import { Fragment } from "react";

export type FilterOption = {
  key: string;
  value: string;
  disabled: boolean;
};

type FilterProps = {
  value: string;
  id?: string;
  options: { key: string; value: string | FilterOption[] }[];
  placeholder: string;
  onChange: Function;
};

const Filter = ({ value, id, options, placeholder, onChange }: FilterProps) => (
  <>
    <select
      value={value}
      id={id || undefined}
      onChange={(e) => onChange(e.target.value)}
      className="filter"
    >
      <option key="" value="" disabled>
        {placeholder}
      </option>

      {options.map((option) => (
        <Fragment key={option.key}>
          {option.value instanceof Array ? (
            <optgroup label={option.key}>
              {option.value.map((o: FilterOption) => (
                <option key={o.key} value={o.key} disabled={o.disabled}>
                  {o.value}
                </option>
              ))}
            </optgroup>
          ) : (
            <option value={option.key}>{option.value}</option>
          )}
        </Fragment>
      ))}
    </select>
  </>
);

Filter.defaultProps = {
  id: "",
};

export default Filter;
