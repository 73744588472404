import { TrackedLink } from "@components/analytics/TrackedLink";
import { VideoBox } from "@components/doc/VideoBox";
import theme from "@config/theme";

const NewToBlues = () => (
  <>
    <div className="new-to-blues">
      <div>
        <h2>New to Blues?</h2>
        <p className="p-with-link">
          Learn what we do in{" "}
          <TrackedLink href="https://www.youtube.com/watch?v=bWDCkwl1wgg">
            this 3-minute video
          </TrackedLink>{" "}
          that walks you through how we simplify wireless connectivity.
        </p>
        <p className="p-with-no-link">
          Learn what we do in this 3-minute video that walks you through how we 
          simplify wireless connectivity.
        </p>
      </div>
      <div className="video-container">
        <VideoBox youtubeEmbedUrl="https://www.youtube.com/embed/bWDCkwl1wgg?si=ZpYwYlqAHxfWCt8v" />
      </div>
    </div>
    <style jsx>{`
      .new-to-blues {
        display: flex;
        padding: 1.5rem;
        margin: 1.5rem 0;
        border: 1px solid ${theme.colors.ultramarineTint};
        background: ${theme.colors.ultramarineTintLightest};
        justify-content: space-between;
        align-items: center;
        gap: 0 1rem;
      }
      h2 {
        margin-top: 0;
      }
      .p-with-link {
        display: none;
      }
      .video-container {
        min-width: 325px;
        max-width: 350px;
      }

      :global(.video-container .video-box) {
        margin: 0;
      }

      @media (max-width: ${theme.breakpoints.betweenSmMd}px) {
        .p-with-no-link,
        .video-container {
          display: none;
        }
        .p-with-link {
          display: block;
        }
      }
    `}</style>
  </>
);

export default NewToBlues;
