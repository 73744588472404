import { useState } from "react";
import { ImgSrc } from "@config/image";
import theme from "@config/theme";
import Project from "./CommunityProject";
import Tags from "../Tags";

type CommunityProjectListProps = {
  category: string;
  projects: Project[];
  viewAll: boolean;
};

const CommunityProjectList = ({
  category,
  projects,
  viewAll,
}: CommunityProjectListProps) => {
  const [isViewingAll, setViewingAll] = useState(viewAll);
  return (
    <>
      <h2 id={category}>{category}</h2>
      <div className="featured-card-list">
        {(isViewingAll ? projects : projects.slice(0, 3)).map((project) => (
          <a key={project.id} href={project.url} target="_blank">
            <div className="featured-card">
              <img
                alt={`${project.title} banner`}
                src={project.coverImageUrl}
              />

              <div className="featured-card-content">
                <Tags tags={[{ name: project.difficulty }]} />
                <h3>{project.title}</h3>
                <p className="description">{project.description}</p>
                <p className="author">
                  <span>By {project.author}</span>
                </p>

                <div className="stat-row">
                  <span>
                    <img
                      alt="Number of likes"
                      src={ImgSrc(`/images/icons/thumbs-up.svg`)}
                    />
                    <span>{project.respects}</span>

                    <img
                      alt="Number of views"
                      src={ImgSrc(`/images/icons/views.svg`)}
                    />
                    <span>{project.views}</span>
                  </span>
                  <img
                    alt="View full project details in new tab"
                    src={ImgSrc("/images/icons/launch.svg")}
                  />
                </div>
              </div>
            </div>
          </a>
        ))}
      </div>

      {category !== "Trending" && (
        <button
          type="button"
          className="btn btnSecondary"
          onClick={() => setViewingAll(!isViewingAll)}
        >
          <span className="button-contents">
            <span>{isViewingAll ? "See less" : "See more"}</span>
            <span className="material-icons-round">
              {isViewingAll ? "arrow_upward" : "arrow_downward"}
            </span>
          </span>
        </button>
      )}

      <style jsx>{`
        .description {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .featured-card-list h3 {
          min-height: 85px;
        }

        .author {
          color: ${theme.colors.gray3};
          min-height: 48px;
          display: flex;
          align-items: flex-end;
        }
        .stat-row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: ${theme.colors.notehubBlueTintLighter};
        }

        .stat-row span {
          margin-right: 15px;
          display: flex;
          align-items: center;
        }
        .stat-row img {
          height: 20px;
          width: 20px;
          margin-right: 5px;
        }

        .stat-row > img {
          height: 25px;
          width: 25px;
        }

        .button-contents {
          display: flex;
          align-items: center;
          justify-content: center;
          grid-gap: 4px;
        }
      `}</style>
    </>
  );
};

export default CommunityProjectList;
