import Link from "next/link";
import theme from "@config/theme";
import { ImgSrc } from "@config/image";

type PaginationLinkProps = {
  title: string;
  href: string;
  type: "previous" | "next";
};

const PaginationLink = ({ title, href, type }: PaginationLinkProps) => (
  <>
    <Link href={href} legacyBehavior>
      <a className={`btnPagination ${type}`} title={title}>
        {title}
      </a>
    </Link>
    <style jsx>{`
      a {
        margin: 3rem 0;
        float: ${type === "previous" ? "left" : "right"};
        clear: ${type === "previous" ? "left" : "right"};
      }

      .btnPagination.previous,
      .btnPagination.next {
        font-size: ${theme.fonts.size.px18};
        color: ${theme.colors.white};
        font-weight: bold;
        position: relative;
        background: ${theme.colors.ultramarineTint};
        min-width: 13.5rem;
        max-width: 21.875rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .btnPagination.previous:hover,
      .btnPagination.next:hover {
        color: ${theme.colors.white};
        background: ${theme.colors.ultramarine};
      }

      .btnPagination.previous {
        text-align: right;
        padding: 2.625rem 1rem 1rem 50px;
      }

      .btnPagination.next {
        text-align: left;
        padding: 2.625rem 3.125rem 1rem 1rem;
      }

      .btnPagination.previous::before,
      .btnPagination.next::before {
        font-size: ${theme.fonts.size.px14};
        font-weight: 400;
        color: ${theme.colors.notehubBlueTintLightest};
        position: absolute;
      }

      .btnPagination.previous::before {
        content: "PREV";
        top: 1rem;
        right: 1rem;
      }

      .btnPagination.next::before {
        content: "NEXT";
        top: 1rem;
      }

      .btnPagination.previous::after,
      .btnPagination.next::after {
        color: ${theme.colors.white};
        content: " ";
        position: absolute;
        height: 1.125rem;
        width: 1.125rem;
        top: 40%;
      }

      .btnPagination.previous::after {
        background-image: url("${ImgSrc("/images/icons/arrow-left.svg")}");
        left: 1rem;
      }

      .btnPagination.next::after {
        background-image: url("${ImgSrc("/images/icons/arrow-right.svg")}");
        right: 1rem;
      }
    `}</style>
  </>
);

type PreviousNextProps = {
  title: string;
  href: string;
};

export const Previous = ({ title, href }: PreviousNextProps) => (
  <PaginationLink title={title} href={href} type="previous" />
);

export const Next = ({ title, href }: PreviousNextProps) => (
  <PaginationLink title={title} href={href} type="next" />
);

export default PaginationLink;
