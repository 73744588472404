import * as React from "react";
import theme from "@config/theme";

type ResponseMembersContext = {
  children: React.ReactNode;
};

const ResponseMembers = ({ children }: ResponseMembersContext) => (
  <>
    <div className="floatfollower" />
    <details open className="responseMembers">
      <summary className="responseMembers">Response Members</summary>
      {children ? (
        <div className="responseMembers">{children}</div>
      ) : (
        <div className="noneMessage">
          None: an empty object <code>{"{}"}</code> means success.
        </div>
      )}
    </details>
    <style jsx>{`
      // Force a break clear across the document so we end up below the
      // <Arguments> and <ExampleRequests>
      .floatfollower {
        box-sizing: border-box;
        clear: both;
        width: 100%;
      }
      details.responseMembers {
        margin-top: 40px;
        margin-bottom: 1rem;
        border: 1px solid ${theme.colors.snowBlue};
        border-radius: 4px;
        padding: 0;
      }
      div.responseMembers {
        display: grid;
        overflow: hidden;
        grid-template-columns: 1fr 3fr; /* 25% 75% column widths */
        grid-template-areas: "arg info"; /* column names we can use below */
      }
      .noneMessage {
        margin: 1rem;
      }
      summary.responseMembers {
        color: ${theme.colors.black};
        font-size: ${theme.fonts.size.px18};
        font-style: normal;
        font-weight: bold;
        margin: 1rem;
      }
      // Font size
      .responseMembers :global(*) {
        font-size: ${theme.fonts.size.px14};
      }
      // Horizontal line divider between members
      .responseMembers :global(h3) {
        // above each h3
        border-top: 1px solid ${theme.colors.snowBlue};
      }
      .responseMembers :global(h3 + *) {
        // and above the cell to the right of the h3
        border-top: 1px solid ${theme.colors.snowBlue};
      }
      .responseMembers :global(h3:first-child) {
        // but not the first h3
        border-top: 0px;
      }
      .responseMembers :global(h3:first-child + *) {
        // nor its neighbor
        border-top: 0px;
      }
      // Argument Names
      .responseMembers :global(h3 code) {
        font-size: ${theme.fonts.size.px14};
      }
      .responseMembers :global(h3) {
        margin: 0px;
        padding: 16px;
        grid-column-start: arg;
      }
      // Member Data Types
      .responseMembers :global(h3 + p em) {
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        color: ${theme.colors.mediumGray};
        display: block;
      }
      .responseMembers :global(h3 + p) {
        padding-top: 16px;
      }
      .responseMembers :global(p) {
        padding: 0px 16px 16px 16px;
        grid-column-start: info;
        grid-column-end: info;
        margin-top: 0px;
        margin-bottom: 0px;
      }
      @media screen and (max-width: ${theme.breakpoints.md}px) {
        .responseMembers :global(p) {
          grid-column-start: 1;
        }
        .responseMembers :global(h3 + *) {
          border-top: 0px;
        }
        .responseMembers :global(h3) {
          grid-column-end: -1;
        }
        .responseMembers :global(h3 + p) {
          padding-top: 0px;
        }
      }
    `}</style>
  </>
);

export default ResponseMembers;
