/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
// DocImage takes an MDX image like this:
//
//    ![Micro USB port on Notecard ](/images/quickstart/micro-usb-port.jpg "Here's where the port is")
//
// It returns a react component which features:
//
//  - LazyLoading (with a placeholder)
//  - File size optimized for different screen sizes
//  - A caption underneath the image if a title is provided ("Here's where the port is")

import { OptimizedImage } from "@components/OptimizedImage";

export const DocImage = (props: React.ImgHTMLAttributes<HTMLImageElement>) => (
  <>
    <span className="doc-image">
      <OptimizedImage {...props} />
      {props.title && (
        <span className="doc-image-caption">
          <i>{props.title}</i>
        </span>
      )}
    </span>
    <style jsx>
      {`
        .doc-image,
        .doc-image-caption {
          display: block;
        }
      `}
    </style>
  </>
);

const DEFAULT = { DocImage };
export default DEFAULT;
