/* eslint-disable no-underscore-dangle */
import { Fragment, ReactElement, useEffect, useState } from "react";
import {
  InternalDocSearchHit,
  StoredDocSearchHit,
} from "@docsearch/react/dist/esm/types";
import { Config } from "@configured/EnvironmentConfig";
import { SmartLink } from "@components/doc/SmartLink";
import theme from "@config/theme";
import SectionIcon from "./icons/SectionIcon";
import PageIcon from "./icons/PageIcon";

const debug = false;
const showURL = debug;
const showJSON = debug;

export function mark(title: string) {
  const parts = [] as (ReactElement | string)[];
  let t = title;
  let key = 0;
  while (t.includes("<mark>") && t.includes("</mark>")) {
    const start = t.indexOf("<mark>");
    const end = t.indexOf("</mark>");
    if (start > 0) {
      parts.push(<Fragment key={key}>{t.substring(0, start)}</Fragment>);
      key += 1;
    }
    parts.push(<mark key={key}>{t.substring(start + 6, end)}</mark>);
    key += 1;
    t = t.substring(end + 7);
  }
  parts.push(<Fragment key={key}>{t}</Fragment>);
  return <>{parts}</>;
}

// type guard to prove type is lvl0, lvl1, lvl2, lvl3, lvl4, lvl5, or lvl6
const isHierarchy = (
  type: string,
): type is "lvl0" | "lvl1" | "lvl2" | "lvl3" | "lvl4" | "lvl5" | "lvl6" =>
  ["lvl0", "lvl1", "lvl2", "lvl3", "lvl4", "lvl5", "lvl6"].includes(type);

// type guard to prove type is 'content'
const isContent = (type: string): type is "content" => type === "content";

// type guard to prove hit has _snippetResult
const hasSnippet = (
  hit: InternalDocSearchHit | StoredDocSearchHit,
): hit is InternalDocSearchHit => "_snippetResult" in hit;

// type guard to prove hit has _highlightResult
const hasHighlight = (
  hit: InternalDocSearchHit | StoredDocSearchHit,
): hit is InternalDocSearchHit => "_highlightResult" in hit;

// type guard to prove hit has hierarchy
const hasHierarchy = (
  hit: InternalDocSearchHit | StoredDocSearchHit,
): hit is InternalDocSearchHit => "hierarchy" in hit;

export const getTitle = (
  hit: InternalDocSearchHit | StoredDocSearchHit,
  fallback = "[snippet not available]",
) => {
  const { type } = hit;

  try {
    const candidates = [
      {
        prefix: "snippet-hierarchy",
        value:
          hasSnippet(hit) &&
          isHierarchy(type) &&
          hit._snippetResult.hierarchy?.[type]?.value,
      },
      {
        prefix: "snippet-hierarchy-camel",
        value:
          hasSnippet(hit) &&
          isHierarchy(type) &&
          hit._snippetResult.hierarchy_camel?.[0]?.[type]?.value,
      },
      {
        prefix: "snippet-content",
        value:
          hasSnippet(hit) &&
          isContent(type) &&
          hit._snippetResult?.content?.value,
      },
      {
        prefix: "highlight-hierarchy",
        value:
          hasHighlight(hit) &&
          isHierarchy(type) &&
          hit._highlightResult?.hierarchy?.[type]?.value,
      },
      {
        prefix: "highlight-hierarchy-camel",
        value:
          hasHighlight(hit) &&
          isHierarchy(type) &&
          hit._highlightResult?.hierarchy_camel?.[0]?.[type]?.value,
      },
      {
        prefix: "highlight-content",
        value:
          hasHighlight(hit) &&
          isContent(type) &&
          hit._highlightResult?.content?.value,
      },
      {
        prefix: "hierarchy",
        value: hasHierarchy(hit) && isHierarchy(type) && hit.hierarchy?.[type],
      },
      {
        prefix: "content",
        value: hit.content,
      },
    ];

    let title = fallback;

    candidates.reverse().forEach((candidate) => {
      if (candidate.value) {
        title = `${candidate.value}`;
        if (debug) {
          title = `${candidate.prefix}: ${title}`;
        }
      }
    });
    return title;
  } catch (e) {
    return `${fallback} + ${e}`;
  }
};

// ResultLink is a Blues component created in the spirit of Algolia's DocSearch.
// That is, in order to keep the DocSearch styling and keyboard navigation we're
// using many of the same classes they usually use.
export const ResultLink = (props: {
  hit: InternalDocSearchHit | StoredDocSearchHit;
}) => {
  const { hit } = props;
  const [url, setUrl] = useState(hit.url);

  const onBrowserOnly = useEffect;
  onBrowserOnly(() => {
    // Turn relative links into absolute links to make SmartLink happy.
    setUrl(url.replace(Config.siteURL, ""));
  });

  const { lvl0, lvl1, lvl2, lvl3, lvl4, lvl5, lvl6 } = hit.hierarchy;
  let path = [lvl0, lvl1, lvl2, lvl3, lvl4, lvl5, lvl6]
    .filter(Boolean)
    .join(" > ");

  const title = getTitle(hit);

  const titleElement = <span>{mark(title)}</span>;

  const iconForType = (type: string) => {
    switch (type) {
      case "lvl0":
        return <PageIcon />;
      case "content":
      case "lvl1":
      case "lvl2":
      case "lvl3":
      case "lvl4":
      case "lvl5":
      case "lvl6":
      default:
        return <SectionIcon />;
    }
  };

  return (
    <>
      <SmartLink href={url}>
        <div className="DocSearch-Hit-Container">
          <div className="DocSearch-Hit-icon">{iconForType(hit.type)}</div>
          <div className="DocSearch-Hit-content-wrapper">
            <span className="DocSearch-Hit-path">
              {/* {hit.hierarchy[hit.type === "content" ? "lvl1" : hit.type]} */}
              {path} {showURL ? `(${url})` : ""}
              {/* … notecard ATTN pin and a <mark>GPI</mark>O pin on the host … */}
            </span>
            <span className="DocSearch-Hit-title">
              {titleElement}
              {
                showJSON ? <><br />{JSON.stringify(hit)}</> : "" /* prettier-ignore */
              }
            </span>
          </div>
        </div>
      </SmartLink>
      <style jsx global>
        {`
          .DocSearch-Modal {
            // Some other styles we may want to override:
            // --docsearch-main-color: #458ee1;
            // --docsearch-layout-type: normal;
            // --docsearch-layout-width: normal;
            // --docsearch-layout-alignment: align;
            // --docsearch-background-color: #fff;
            // --docsearch-border-radius: 4;
            // --docsearch-border-width: 1;
            // --docsearch-border-color: #d9d9d9;
            // --docsearch-box-shadow: light;
            // --docsearch-branding-position: bottom;
            // --docsearch-spacing: normal;
            // --docsearch-include-desc: yes;
            // --docsearch-background-category-header: #ffffff;
            // --docsearch-font-size: normal;
            // --docsearch-header-color: #33363d;
            // --docsearch-title-color: #02060c;
            // --docsearch-subtitle-color: #a4a7ae;
            // --docsearch-text-color: #63676d;
            --docsearch-highlight-color: ${theme.colors.ultramarine};
            --docsearch-primary-color: ${theme.colors.ultramarine};
            --docsearch-searchbox-shadow: inset 0 0 0 2px
              ${theme.colors.ultramarine};
            --docsearch-modal-height: 75vh;
            // --docsearch-highlight-opacity: 0.1;
            // --docsearch-highlight-type: underline;
          }

          .DocSearch-Hit a {
            // background-color: ${theme.colors.lightGray};
            border-radius: 4px;
            // transparent border
            border: 2px solid transparent;
          }
          .DocSearch-Hit[aria-selected="true"] a {
            background-color: ${theme.colors.lightGray};
            --docsearch-hit-active-color: ${theme.colors.darkCharlestonGreen};
            border-radius: 4px;
            border: 2px solid ${theme.colors.ultramarine};
          }
        `}
      </style>
    </>
  );
};
