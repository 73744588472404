import { Config } from "@configured/EnvironmentConfig";
import { ImageLoaderProps } from "next/image";

function cloudinaryParams(width: number, quality: number | undefined) {
  const params = ["f_auto", "c_limit", `w_${width}`, `q_${quality || "auto"}`];
  return params.join(",");
}

function cloudinaryPrefix(
  imageCdnUrl: string,
  width: number,
  quality: number | undefined,
) {
  const paramsString = cloudinaryParams(width, quality);
  const cloudinaryPath = "image/fetch";
  return `${imageCdnUrl}/${cloudinaryPath}/${paramsString}`;
}

const CDNUrlForImage = ({ src, width, quality }: ImageLoaderProps) => {
  const { imageCdnUrl, siteURL } = Config;
  const CDNPrefix = cloudinaryPrefix(imageCdnUrl, width, quality);
  return `${CDNPrefix}/${siteURL}${src}`;
};

export { CDNUrlForImage };
