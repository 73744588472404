const YouTubeLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 16"
    width="24"
    height="24"
  >
    <g>
      <path
        fill="currentColor"
        d="m22.52,2.499c-.266-.985-1.041-1.755-2.033-2.02-1.794-.479-8.987-.479-8.987-.479,0,0-7.193,0-8.987.476C1.521.741.746,1.511.48,2.497c-.48,1.783-.48,5.503-.48,5.503,0,0,0,3.72.48,5.501.264.983,1.045,1.758,2.033,2.02,1.794.479,8.987.479,8.987.479,0,0,7.193,0,8.987-.479.991-.262,1.769-1.037,2.033-2.02.48-1.781.48-5.501.48-5.501,0,0,0-3.72-.48-5.501Zm-13.305,8.915v-6.828l5.955,3.389-5.955,3.439Z"
      />
    </g>
  </svg>
);

export default YouTubeLogo;
