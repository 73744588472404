import theme from "@config/theme";

const CheckMark = () => (
  <span data-tooltip-id="copied-tooltip">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 -960 960 960"
      width="48"
      fill="#73CC3A"
    >
      <path
        fill="currentColor"
        d="M378-258q-6 0-11-2t-10-7L176-448q-9-9-9-22t9-22q9-9 21-9t21 9l160 160 363-363q9-9 21.5-9t21.5 9q9 9 9 21.5t-9 21.5L399-267q-5 5-10 7t-11 2Z"
      />
    </svg>
    <style jsx>
      {`
        svg {
          color: ${theme.colors.kellyGreen};
          height: 28px;
          width: 28px;
          position: relative;
          display: inline-block;
        }
      `}
    </style>
  </span>
);

export default CheckMark;
