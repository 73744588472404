import theme from "@config/theme";

const ZENDESK_EMBED =
  "https://static.zdassets.com/ekr/snippet.js?key=08ae69df-fe94-4803-99d0-257c4f1bd859";

declare global {
  interface Window {
    zE: any;
  }
}

const OpenSupportWidget = () => {
  const promise = new Promise((resolve) => {
    const script = document.createElement("script");

    script.src = ZENDESK_EMBED;
    script.id = "ze-snippet";
    script.async = true;

    document.body.appendChild(script);

    const init = () => {
      const msRetry = 200;
      if (typeof window.zE === "undefined") {
        setTimeout(init, msRetry);
        return;
      }
      window.zE("messenger:set", "zIndex", theme.zIndex.supportChat);
      window.zE("messenger", "show");
      window.zE("messenger", "open");
      resolve(true);
    };

    init();
  });

  return promise;
};

export default OpenSupportWidget;
