import { TrackedLink } from "@components/analytics/TrackedLink";
import { ImgSrc } from "@config/image";
import theme from "@config/theme";
import { stringToURI } from "@scripts/utils";

type QuickstartStepProps = {
  title: string;
  description: string;
  imageURL: string;
  link: string;
  linkDescription: string;
  testId: string;
};

const QuickstartStep = ({
  title,
  description,
  imageURL,
  link,
  linkDescription,
  testId,
}: QuickstartStepProps) => (
  <>
    <div className="step">
      <div className="step-image-half">
        <img alt="" src={ImgSrc(imageURL)} />
      </div>
      <div className="step-description-half">
        <h2 id={stringToURI(title)}>{title}</h2>
        <p>{description}</p>
        <TrackedLink className="btn" href={link} data-testid={testId}>
          {linkDescription}
        </TrackedLink>
      </div>
    </div>

    <style jsx>{`
      .step {
        display: flex;
        clear: both;
        padding: 2rem 0;
      }
      .step-image-half {
        display: flex;
        flex: 0 0 30%;
        justify-content: center;
      }
      .step-description-half {
        padding-left: 2rem;
      }

      /* Override the default styling of h2 elements in doc files, as we
      don't want the dotted lines or excessive padding/margin here */
      .step h2 {
        margin-top: 0;
        border: none;
        padding-top: 0;
      }
      /* Ensure menu clicks on section titles continue to show the headings.
      We have handling in place for this on h2 elements on the docs, but it doesn't
      work with these headers because we remove the padding above. */
      .step h2::before {
        content: "";
        display: block;
        height: 65px;
        margin-top: -65px;
        visibility: hidden;
      }

      img {
        height: 200px;
        width: 200px;
      }
      @media screen and (max-width: ${theme.breakpoints.md}px) {
        img {
          height: 150px;
          width: 150px;
        }
      }

      @media screen and (max-width: ${theme.breakpoints.sm}px) {
        .step {
          flex-direction: column;
        }
        .step-image-half {
          justify-content: flex-start;
        }
        .step-description-half {
          padding-left: 0;
        }
        .step h2 {
          margin-top: 1rem;
        }
      }
    `}</style>
  </>
);

export default QuickstartStep;
