/* eslint-disable react/destructuring-assignment */
import { PropsWithChildren, createContext, useContext, useId } from "react";
import classNames from "classnames";
import { stringToURI } from "@scripts/utils";
import theme from "@config/theme";

type TabContentProps = {
  children: React.ReactNode;
  className?: string;
  title: string;
};

type TabsProps = {
  children: React.ReactNode;
};

type TabsContextValue = {
  tabsGroupName?: string;
  defaultTabTitle?: string;
};

export const TabsContext = createContext<TabsContextValue>({});

const TabsDefaultStyle = ({ children }: PropsWithChildren) => (
  <>
    {children}
    <style jsx>{`
      :global(
          .tabs-context > :not(.tab-input):not(.tab-label):not(.tab-content)
        ) {
        width: 100%;
      }

      :global(.tab-label) {
        flex-grow: 1;
        font-size: 1.125rem;
        font-weight: 500;
        color: ${theme.colors.black};
        padding: 0 0.5rem;
        border-bottom: 3px solid ${theme.colors.gray5};
        margin: 0.2rem 20px 0.2rem 0.2px;
      }

      :global(.tab-label:hover) {
        cursor: pointer;
        background-color: ${theme.colors.gray5};
      }

      // Has keyboard focus
      :global(.tab-input:focus-visible + .tab-label) {
        // use box-shadow so it doesn't affect layout
        box-shadow: inset 0 0 0 3px ${theme.colors.ultramarineTint};
      }
      // Selected tab
      :global(.tab-input:checked + .tab-label) {
        border-bottom-color: ${theme.colors.ultramarineTint};
      }
    `}</style>
  </>
);

export const Tabs = ({ children }: TabsProps) => {
  const ctx = {
    tabsGroupName: useId(),
    defaultTabTitle: undefined, // To be updated directly by TabContent
  };

  return (
    <TabsContext.Provider value={ctx}>
      <TabsDefaultStyle>
        <div className="tabs-context">{children}</div>
        <style jsx>{`
          .tabs-context {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
          }
        `}</style>
      </TabsDefaultStyle>
    </TabsContext.Provider>
  );
};

export const TabContent = ({ children, title, className }: TabContentProps) => {
  const id = useId();
  const titleURI = stringToURI(title);
  const ctx = useContext(TabsContext);

  // Set default tab for group if not set
  if (!ctx.defaultTabTitle) {
    ctx.defaultTabTitle = title;
  }
  const isDefaultChecked = ctx.defaultTabTitle === title;

  return (
    <>
      <input
        className="tab-input visually-hidden"
        data-tab-title={titleURI}
        defaultChecked={isDefaultChecked}
        id={id}
        name={ctx.tabsGroupName || undefined}
        tabIndex={0}
        type="radio"
        value={titleURI}
      />
      <label className={classNames(["tab-label", className])} htmlFor={id}>
        {title}
      </label>
      <div
        data-for-tab-id={id}
        className={classNames(["tab-content", className])}
      >
        {children}
      </div>
      <style jsx>{`
        .tab-content {
          display: none;
          width: 100%;
          overflow: hidden;
          order: -1; // move to top
        }
        input + .tab-label {
          order: -2; // move to top
        }
        input:checked + * + .tab-content {
          // Selected tab content
          display: unset;
        }
        :global(*:not(.tabs-context)) > .tab-content {
          display: unset;
        }
        :global(*:not(.tabs-context)) > label,
        :global(*:not(.tabs-context)) > input {
          display: none;
        }
        .visually-hidden {
          position: absolute;
          width: 1px;
          height: 1px;
          padding: 0;
          margin: -1px;
          overflow: hidden;
          clip: rect(0, 0, 0, 0);
          border: 0;
        }
      `}</style>
    </>
  );
};

TabContent.defaultProps = {
  className: "",
};
