import theme from "@config/theme";
import { getCategorizedOptions } from "@content/en/Options";
import { Selections } from "@scripts/SlugPickerUtils";
import { HumanOptions } from "@scripts/utils";
import { useId } from "react";

type CategorySelectProps = {
  options: Record<string, string[]>;
  selected: Selections;
  text?: string;
  onChange: (key: string, value: string, isGrayedOut?: boolean) => void;
};

const CategorySelect = ({
  onChange,
  options,
  selected,
  text,
}: CategorySelectProps) => {
  const key = Object.keys(options)[0];
  const categorizedSlugs = getCategorizedOptions(key, options[key]);
  const id = useId();

  return (
    <>
      <div className="filter-container">
        <label htmlFor={id}>{text}</label>
        <select
          id={id}
          onChange={(e) => onChange(key, e.target.value)}
          value={selected[key] || ""}
        >
          {Object.entries(categorizedSlugs).map(
            ([category, categoryOptions]) => (
              <optgroup key={category} label={category}>
                {categoryOptions.map((option) => (
                  <option key={option} value={option}>
                    {HumanOptions[key][option]}
                  </option>
                ))}
              </optgroup>
            )
          )}
        </select>
      </div>
      <style jsx>
        {`
          .filter-container {
            background-color: ${theme.colors.lightSnowBlue};
            padding: 1rem;
            border-radius: 4px;
            gap: 0.5rem;
          }
          label {
            font-weight: 500;
          }
        `}
      </style>
    </>
  );
};

export default CategorySelect;
