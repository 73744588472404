/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement } from "react";

type Props = {
  children: ReactElement;
  language: string;
  board: string;
  notecarrier: string;
};

const supportsPseudoSensor = (
  language: string,
  board: string,
  notecarrier: string
) => language !== "c-cpp-stm32cube";

const supportsPlatformIO = (
  language: string,
  board: string,
  notecarrier: string
) => language === "c-cpp-arduino-wiring" && board === "blues-swan";

export const IfSupportsPlatformIO = ({
  children,
  language,
  board,
  notecarrier,
}: Props) =>
  supportsPlatformIO(language, board, notecarrier) ? children : null;

export const IfDoesNotSupportPlatformIO = ({
  children,
  language,
  board,
  notecarrier,
}: Props) =>
  supportsPlatformIO(language, board, notecarrier) ? null : children;

export const IfUsingPseudoSensor = ({
  children,
  language,
  board,
  notecarrier,
}: Props) =>
  supportsPseudoSensor(language, board, notecarrier) ? children : null;

export const IfNotUsingPseudoSensor = ({
  children,
  language,
  board,
  notecarrier,
}: Props) =>
  supportsPseudoSensor(language, board, notecarrier) ? null : children;
