import theme from "@config/theme";

type BadgePermissionProps = {
  type: "viewer" | "developer" | "owner";
};

const BadgePermission = ({ type }: BadgePermissionProps) => (
  <>
    <span className={`badgePermission ${type}`}>{type}</span>

    <style jsx>{`
      .badgePermission {
        font-size: ${theme.fonts.size.px12};
        color: ${theme.colors.gray1};
        text-transform: uppercase;
        padding: 0.15em 0.4em 0.3em 0.4em;
        font-weight: 700;
        letter-spacing: 0.2px;
        border-radius: 3px;
      }

      .owner {
        background-color: ${theme.colors.notehubBlueTintLightest};
      }

      .developer {
        background-color: ${theme.colors.kellyGreenTintLighter};
      }

      .viewer {
        background-color: ${theme.colors.saffronYellowTint};
      }
    `}</style>
  </>
);

export default BadgePermission;
