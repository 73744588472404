import theme from "@config/theme";

interface Props {
  children: React.ReactNode;
  className?: string;
  heightPx: number;
  widthPx: number;
}

const AspectRatioBox = ({ children, className, heightPx, widthPx }: Props) => (
  <>
    <span className={`aspect-ratio-box placeholder ${className}`}>
      <span className="aspect-ratio-box-inside">{children}</span>
    </span>

    <style jsx>{`
      // based on https://css-tricks.com/aspect-ratio-boxes/
      .aspect-ratio-box {
        height: 0;
        overflow: hidden;
        padding-top: ${(100 * heightPx) / widthPx}%;
        display: block; // validateDOMNesting(...): <div> cannot appear as a descendant of <p>
        position: relative; // position children relative to us
      }

      .placeholder {
        background-color: ${theme.colors.lightSnowBlue};
      }

      .aspect-ratio-box-inside {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block; // validateDOMNesting(...): <div> cannot appear as a descendant of <p>
      }
    `}</style>
  </>
);

export { AspectRatioBox };
