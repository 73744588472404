const HacksterLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 23"
    width="24"
    height="24"
  >
    <g>
      <g id="Group_1">
        <g id="Path_1">
          <path
            fill="currentColor"
            d="m11.5,0C5.149,0,0,5.149,0,11.5s5.149,11.5,11.5,11.5,11.5-5.149,11.5-11.5C22.993,5.152,17.848.007,11.5,0m1.575,6.586c.006-.158.111-.295.262-.343l1.872-.511c.098-.039.21.009.249.107.012.029.016.061.013.093v3.602c0,.15-.121.271-.271.271h-1.852c-.15,0-.271-.121-.271-.271h0v-2.948Zm-5.566,1.521c.006-.158.11-.296.262-.344l1.871-.511c.098-.039.21.009.249.107.012.029.016.061.013.093v.695c0,.149-.121.271-.27.271h-1.852c-.15,0-.271-.121-.271-.271h0v-.039Zm2.396,8.345c-.006.158-.111.295-.262.342l-1.872.512c-.098.039-.21-.009-.249-.107-.012-.029-.016-.061-.013-.093v-3.601c0-.15.121-.271.271-.271h1.851c.15,0,.271.121.271.271h0l.002,2.947Zm5.567-1.521c-.006.158-.111.295-.262.342l-1.872.511c-.098.039-.21-.009-.249-.107-.012-.029-.016-.061-.013-.093v-.699c0-.15.12-.272.27-.272h1.852c.15,0,.271.121.271.271,0,0,0,0,0,0v.045Zm2.825-2.191c-.121.06-.25.103-.383.126l-4.578,1.252c-.098.039-.21-.009-.249-.107-.012-.029-.016-.061-.013-.093v-1.183c0-.149-.12-.271-.269-.271,0,0,0,0,0,0h-5.025c-.149,0-.27-.121-.27-.27v-1.375c0-.15-.121-.271-.271-.271h-2.412c-.095,0-.172-.076-.172-.171h0c0-.046.019-.089.053-.12.121-.062.25-.105.383-.128l4.56-1.246c.099-.039.21.01.249.108.011.029.016.06.012.091v1.195c0,.149.122.27.271.27h5.019c.15,0,.271.121.271.271h0v1.362c0,.149.121.271.27.271h2.435c.095,0,.172.077.172.172h0c0,.045-.019.087-.053.116"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default HacksterLogo;
