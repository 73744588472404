import { ReactElement } from "react";
import { Note } from "./Callout";

type VersionCheckProps = {
  children: ReactElement;
  pageProps: {
    notecard_firmware_version: string;
  };
  version: string;
  includeNote?: boolean;
};

const VersionCheck = ({
  children,
  pageProps: { notecard_firmware_version },
  version,
  includeNote,
}: VersionCheckProps) => {
  if (notecard_firmware_version === "Latest") {
    return children;
  }

  const userVersion = notecard_firmware_version
    .replace("LTS", "")
    .trim()
    .charAt(0);
  const featureVersion = version.charAt(0);

  if (userVersion >= featureVersion) {
    return children;
  }

  if (includeNote) {
    return (
      <Note>
        <p>
          This request is not available in the currently selected Notecard
          firmware version.
        </p>
      </Note>
    );
  }

  return null;
};

export default VersionCheck;
