import theme from "@config/theme";
import { ImgSrc } from "@config/image";

type CalloutProps = {
  type: "note" | "success" | "warning" | "deprecated";
  children: string | JSX.Element;
};

const Callout = ({ children, type }: CalloutProps) => (
  <div className={`callout ${type}`}>
    <header>
      <img alt="" src={ImgSrc(`/images/icons/callout-${type}.svg`)} />
      <span>{type}</span>
    </header>
    <div className="border">
      <div className="margin">
        <div className="details">{children}</div>
      </div>
    </div>

    <style jsx>{`
      header {
        background-color: ${theme.colors.notehubBlueTintLighter};
        color: ${theme.colors.white};
        font-family: var(--fira-code-font);
        font-size: ${theme.fonts.size.px18};
        font-weight: 600;
        padding: 15px 20px;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      .note header {
        background-color: ${theme.colors.notehubBlueTintLighter};
      }
      .note .border {
        border-color: ${theme.colors.notehubBlueTintLighter};
      }
      .success header {
        background-color: ${theme.colors.kellyGreen};
      }
      .success .border {
        border-color: ${theme.colors.kellyGreen};
      }
      .warning header {
        background-color: ${theme.colors.poppyRed};
      }
      .warning .border {
        border-color: ${theme.colors.poppyRed};
      }
      .deprecated header {
        background-color: ${theme.colors.poppyRedShade};
      }
      .deprecated .border {
        border-color: ${theme.colors.poppyRedShade};
      }

      .callout {
        box-sizing: border-box;
        margin-top: 1.125rem;
        margin-bottom: 1.125rem;
        overflow: hidden;
      }

      .callout.grid {
        padding: 1rem 1rem 0 1rem;
      }

      header img {
        margin: 0 10px 0 0;
        height: 18px;
      }

      .callout .border {
        overflow: auto; // so margins of children are contained.
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border-width: 0 2px 2px 2px;
        border-style: solid;
      }

      .margin {
        margin: 15px;
      }

      @media print {
        header {
          color: ${theme.colors.text};
        }
        .callout img {
          display: none;
        }
        .note header {
          border: 2px solid ${theme.colors.notehubBlueTintLighter};
        }
        .warning header {
          border: 2px solid ${theme.colors.poppyRed};
        }
      }
    `}</style>
  </div>
);

type SimpleCallout = {
  children: string | JSX.Element;
};
export const Note = ({ children }: SimpleCallout) => (
  <Callout type="note">{children}</Callout>
);
export const Success = ({ children }: SimpleCallout) => (
  <Callout type="success">{children}</Callout>
);
export const Warning = ({ children }: SimpleCallout) => (
  <Callout type="warning">{children}</Callout>
);
export const Deprecated = ({ children }: SimpleCallout) => (
  <Callout type="deprecated">{children}</Callout>
);

export default Callout;
