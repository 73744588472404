/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import theme from "@config/theme";

type BadgeType =
  | "cell"
  | "cell+wifi"
  | "lora"
  | "wifi"
  | "notehub"
  | "deprecated";
type BadgeProps = {
  type: BadgeType;
  usage?: "request" | "argument" | "firmware";
};

const formattedNames = {
  cell: "Cell",
  "cell+wifi": "Cell+WiFi",
  lora: "LoRa",
  wifi: "WiFi",
  notehub: "Notehub",
  deprecated: "Deprecated",
};

const tooltipSuffixes = {
  cell: "all versions of Notecard Cellular",
  "cell+wifi": "Notecard Cell+WiFi",
  lora: "Notecard LoRa",
  wifi: "all versions of Notecard WiFi",
  notehub: "Notehub API",
};

const getTooltipMessage = (type: BadgeType, usage: string) => {
  let text = "";

  if (type === "deprecated") {
    return `This ${usage} is deprecated.`;
  }

  if (usage === "request") {
    text = "This request is available on ";
    if (type === "notehub") {
      text = "This request can be performed using the ";
    }
  }

  if (usage === "argument") {
    text = "This mode is available on ";
  }

  if (usage === "firmware") {
    text = "This method can be used to update the firmware on ";
  }

  return `${text} ${tooltipSuffixes[type]}.`;
};

const Badge = ({ type, usage = "request" }: BadgeProps) => (
  <>
    <span className={`badge-container ${type.replace("+", "-")} ${usage}`}>
      <span
        className="badge"
        onClick={(e) => {
          e.preventDefault();
        }}
        aria-label={getTooltipMessage(type, usage)}
        data-tooltip-id="badge-tooltip"
        data-tooltip-content={getTooltipMessage(type, usage)}
      >
        {formattedNames[type]}
      </span>
    </span>

    <style jsx>{`
      .badge-container {
        position: relative;
      }
      .badge {
        font-size: ${theme.fonts.size.px12};
        font-weight: bold;
        color: ${theme.colors.white};
        text-transform: uppercase;
        margin-left: 5px;
        padding: 0.2rem 0.5rem 0.25rem 0.5rem;
        letter-spacing: 0.2px;
        white-space: nowrap;
        border-radius: 3px;
        position: relative;
        top: -2px;
        cursor: default;
        vertical-align: middle;
      }

      .argument .badge {
        font-size: ${theme.fonts.size.px10};
      }

      .notehub .badge {
        background-color: ${theme.colors.ultramarineShade};
      }
      .cell .badge {
        background-color: ${theme.colors.kellyGreenShade};
      }
      .cell-wifi .badge {
        background-color: ${theme.colors.saffronYellowShade};
      }
      .lora .badge {
        background-color: ${theme.colors.ultramarine};
      }
      .wifi .badge {
        background-color: ${theme.colors.gray1};
      }
      .deprecated .badge {
        background-color: ${theme.colors.poppyRedShade};
      }
    `}</style>
  </>
);

export default Badge;
