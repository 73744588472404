/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Row, Col } from "react-grid-system";
import { useRouter } from "next/router";
import { categorizeHacksterProjects } from "@adapters/hackster/TransformHacksterProject";
import Breadcrumbs from "@components/Breadcrumbs";
import Filter from "@components/Filter";
import hacksterProjects from "@content/en/community/01 Projects/generated.hackster.projects.yaml";
import { stringToURI } from "@scripts/utils";
import CommunityProject from "./CommunityProject";
import CommunityProjectList from "./CommunityProjectList";

type CommunityProjectsUIProps = {
  projects: CommunityProject[];
  category: string;
};

const CommunityProjectsUI = ({
  category,
  projects,
}: CommunityProjectsUIProps) => {
  const router = useRouter();

  const categorizedProjects = categorizeHacksterProjects(projects);
  const categories = Object.keys(categorizedProjects);

  const updateFilter = (value: string) => {
    const slugCategory = stringToURI(value);
    router.push(`/community/projects/${slugCategory}`);
  };

  return (
    <>
      <Container>
        <Row>
          <Col md={12} lg={8}>
            <Breadcrumbs
              links={[
                { name: "Docs", url: "/docs" },
                { name: "Example Apps", url: "/example-apps" },
                { name: "Community Projects", url: "/community/projects" },
              ]}
            />
            <h1>Community Projects</h1>
            <p>
              Blues and our community regularly publish projects on Hackster to
              showcase the things we&apos;re building. Check out these guides
              for reference implementations, and for inspiration for the app you
              might want to build next.
            </p>

            <div className="filter-container">
              <Filter
                value={category}
                placeholder="Filter projects by"
                onChange={updateFilter}
                options={categories.map((c) => ({
                  key: c,
                  value: c,
                }))}
              />
              {category && (
                <button
                  className="button-as-link"
                  onClick={() => updateFilter("")}
                  type="button"
                >
                  Clear Filter
                </button>
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            {category ? (
              <CommunityProjectList
                key={category}
                category={category}
                projects={categorizedProjects[category]}
                viewAll
              />
            ) : (
              categories.map((theCategory) => (
                <CommunityProjectList
                  key={theCategory}
                  category={theCategory}
                  projects={categorizedProjects[theCategory]}
                  viewAll={false}
                />
              ))
            )}
          </Col>
        </Row>
      </Container>
      <style jsx>
        {`
          select {
            min-width: 200px;
          }
        `}
      </style>
    </>
  );
};

// This is the component exposed to Markdown.
const CommunityProjects = (category: { category: string }) => (
  <CommunityProjectsUI {...category} projects={hacksterProjects} />
);

export default CommunityProjects;
