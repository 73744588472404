const LinkedInLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 23"
    width="24"
    height="24"
  >
    <g>
      <path
        fill="currentColor"
        d="m21.33,0H1.728C.787,0,0,.749,0,1.652v19.695c0,.922.518,1.652,1.459,1.652h19.602c.941,0,1.939-.749,1.939-1.652V1.652c0-.903-.73-1.652-1.67-1.652ZM6.566,19.714h-3.283v-10.952h3.283v10.952Zm-1.632-12.72c-1.133,0-2.054-.922-2.054-2.056s.922-2.056,2.054-2.056,2.054.922,2.054,2.056-.922,2.056-2.054,2.056Zm14.764,12.72h-3.283v-5.476c0-1.46-.576-2.729-1.939-2.729-1.651,0-2.438,1.114-2.438,2.959v5.265h-3.283v-10.972h3.091v1.576h.038c.48-.845,1.862-1.71,3.59-1.71,3.302,0,4.224,1.749,4.224,5.015v6.072Z"
      />
    </g>
  </svg>
);

export default LinkedInLogo;
