import { ReactNode } from "react";

interface BotControlProps {
  children: ReactNode;
  allow: boolean;
}

const BotControl = ({ children, allow }: BotControlProps) => (
  // Iff allow===false, prevent certain content from being crawled by search
  // engine bots and also aid in accessibility for screen readers
  <div
    className="bot-control"
    aria-hidden={!allow || undefined}
    data-search-exclude={!allow || undefined}
  >
    {children}
  </div>
);

export default BotControl;
