import { ReactNode } from "react";
import theme from "@config/theme";

type ExampleResponseContent = {
  children: ReactNode;
};

const ExampleResponse = ({ children }: ExampleResponseContent) => (
  <>
    <div className="exampleResponse">
      <header>Example Response</header>
      {children}
    </div>
    <style jsx>{`
      .exampleResponse {
        margin-top: 1rem;
      }

      .exampleResponse :global(.codeBlockContainer) {
        background-color: ${theme.colors.gray5};
        border-radius: 0px 0px 4px 4px;
        margin-top: 0;
      }

      header {
        color: ${theme.colors.gray1};
        font-weight: bold;
        padding: 12px 16px;
        background-color: ${theme.colors.lightGray};
        border-radius: 4px 4px 0px 0px;
      }

      @media screen and (min-width: ${theme.breakpoints.md}px) {
        // share the screen with the ResponseMembers.
        .exampleResponse,
        :global(details.responseMembers) {
          box-sizing: border-box;
          float: left;
          width: 50%;
        }

        .exampleResponse {
          padding-left: 32px;
          margin-top: 40px;
        }
      }

      /* styling for react-syntax-highlighter components (example response styling) */
      /* see CodeBlock.tsx for default (aka example request) code block styling */
      .exampleResponse :global(.codeBlockContainer .hljs) {
        color: ${theme.colors.gray2};
        border-radius: 0px 0px 4px 4px;
      }

      .exampleResponse :global(.codeBlockContainer .hljs-keyword),
      .exampleResponse :global(.codeBlockContainer .hljs-literal),
      .exampleResponse :global(.codeBlockContainer .hljs-symbol),
      .exampleResponse :global(.codeBlockContainer .hljs-name) {
        color: ${theme.colors.darkTurquoise};
      }

      .exampleResponse :global(.codeBlockContainer .hljs-link) {
        color: ${theme.colors.darkTurquoise};
        text-decoration: underline;
      }

      .exampleResponse :global(.codeBlockContainer .hljs-subst),
      .exampleResponse :global(.codeBlockContainer .hljs-function),
      .exampleResponse :global(.codeBlockContainer .hljs-title),
      .exampleResponse :global(.codeBlockContainer .hljs-params),
      .exampleResponse :global(.codeBlockContainer .hljs-formula),
      .exampleResponse :global(.codeBlockContainer .hljs-formula),
      .exampleResponse :global(.codeBlockContainer .hljs-attr),
      .exampleResponse :global(.codeBlockContainer .hljs-attribute),
      .exampleResponse :global(.codeBlockContainer .hljs-builtin-name),
      .exampleResponse :global(.codeBlockContainer .hljs-bullet),
      .exampleResponse :global(.codeBlockContainer .hljs-selector-tag),
      .exampleResponse :global(.codeBlockContainer .hljs-selector-id),
      .exampleResponse :global(.codeBlockContainer .hljs-selector-class),
      .exampleResponse :global(.codeBlockContainer .hljs-selector-attr),
      .exampleResponse :global(.codeBlockContainer .hljs-selector-pseudo) {
        color: ${theme.colors.gray2};
      }

      .exampleResponse :global(.codeBlockContainer .hljs-comment),
      .exampleResponse :global(.codeBlockContainer .hljs-quote),
      .exampleResponse :global(.codeBlockContainer .hljs-meta),
      .exampleResponse :global(.codeBlockContainer .hljs-meta-keyword),
      .exampleResponse :global(.codeBlockContainer .hljs-tag) {
        color: ${theme.colors.mediumGray};
      }

      .exampleResponse :global(.codeBlockContainer .hljs-section),
      .exampleResponse :global(.codeBlockContainer .hljs-built_in),
      .exampleResponse :global(.codeBlockContainer .hljs-type) {
        color: ${theme.colors.notehubBlue};
      }

      .exampleResponse :global(.codeBlockContainer .hljs-variable),
      .exampleResponse :global(.codeBlockContainer .hljs-template-variable) {
        color: ${theme.colors.gray1};
      }

      .exampleResponse :global(.codeBlockContainer .hljs-doctag),
      .exampleResponse :global(.codeBlockContainer .hljs-number),
      .exampleResponse :global(.codeBlockContainer .hljs-class) {
        color: ${theme.colors.poppyRed};
      }

      .exampleResponse :global(.codeBlockContainer .hljs-string),
      .exampleResponse :global(.codeBlockContainer .hljs-meta-string) {
        color: ${theme.colors.notehubBlueTint};
      }

      .exampleResponse :global(.codeBlockContainer .hljs-regexp),
      .exampleResponse :global(.codeBlockContainer .hljs-template-tag) {
        color: ${theme.colors.saffronYellowShade};
      }
    `}</style>
  </>
);
export default ExampleResponse;
