import theme from "@config/theme";

type TermProps = {
  children: React.ReactNode;
};

// Terms for each group
export const Term = ({ children }: TermProps) => (
  <div className="termContainer">
    {children}

    <style jsx>{`
      // container
      .termContainer {
        padding: 0 20px 36px 0;
        border-bottom: 1px solid ${theme.colors.snowBlue};
      }

      // only apply these styles to the last element with class of termContainer
      :nth-last-child(1 of .termContainer) {
        border-bottom: none;
        padding-bottom: 0;
      }
    `}</style>
  </div>
);

type TermExampleProps = {
  children: React.ReactNode;
};

// Example component within Term
export const TermExample = ({ children }: TermExampleProps) => (
  <details>
    <summary>Example</summary>

    {children}

    <style jsx>{`
      details {
        border: 0;
        padding: 2px 0 12px 0;
        margin: 0;
      }

      details summary {
        font-size: ${theme.fonts.size.px16};
      }
    `}</style>
  </details>
);
