import { ComponentType, useEffect, useState } from "react";
import { TryExampleInteractor } from "@use-cases/TryExampleInteractor";

interface AddedProps {}
interface InterceptedProps {
  children?: string;
  input?: string;
  onTryClick?: () => void;
  output?: string;
}
function getDisplayName(WrappedComponent: any) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}
export const ExampleSendingCodeBlock = <Props extends InterceptedProps>(
  WrappedComponent: ComponentType<Props>,
  interactor: TryExampleInteractor
): ComponentType<Props & AddedProps> => {
  const OuterComponent: ComponentType<Props & AddedProps> = ({ ...props }) => {
    let toTry = props.input || props.children || props.output;

    // If a CodeBlock is used with triple back ticks (```),
    // its contents are in its child component.
    if (typeof toTry !== "string") {
      const children = props.children as unknown as {
        props: { children: { toString: () => string } };
      };
      toTry = children?.props?.children?.toString?.() || "";
    }

    const [isTriable, setIsTriable] = useState(false);
    function updateIsTriable() {
      const newIsTriable = !!(toTry && interactor?.isTriable(toTry));
      if (isTriable === newIsTriable) {
        return;
      }
      setIsTriable(newIsTriable);
    }
    updateIsTriable();
    useEffect(() => {
      const t = setInterval(updateIsTriable, 333 /* ms */);
      return () => clearInterval(t);
    });
    const wrappedOnTryClick = () => {
      if (toTry) interactor.try(toTry);
      props.onTryClick?.();
    };
    const onTryClick = isTriable ? wrappedOnTryClick : props.onTryClick;
    return <WrappedComponent {...(props as Props)} onTryClick={onTryClick} />;
  };
  OuterComponent.displayName = `ExampleSendingCodeBlock ${getDisplayName(
    WrappedComponent
  )}`;
  return OuterComponent;
};
