/* eslint-disable @typescript-eslint/naming-convention */
const CELLULAR_V1_SKUS = [
  "note-nbgl-500",
  "note-nbna-500",
  "note-wbex-500",
  "note-wbna-500",
];
export const isCellular_V1_SKU = (sku: string) =>
  CELLULAR_V1_SKUS.includes(sku.toLowerCase());

const CELLULAR_V2_SKUS = [
  "note-nbglw",
  "note-nbnaw",
  "note-wbexw",
  "note-wbnaw",
  "note-wbglw",
  "note-wbglwt",
];
export const isCellular_V2_SKU = (sku: string) =>
  CELLULAR_V2_SKUS.includes(sku.toLowerCase());

const CELLULAR_V3_SKUS = [
  "note-nbgln",
  "note-nbnan",
  "note-wbexn",
  "note-wbnan",
];
export const isCellular_V3_SKU = (sku: string) =>
  CELLULAR_V3_SKUS.includes(sku.toLowerCase());

const CELLULAR_V4_SKUS = [
  "note-mbnaw",
  "note-mbnan",
  "note-mbgln",
  "note-mbglw",
];
export const isCellular_V4_SKU = (sku: string) =>
  CELLULAR_V4_SKUS.includes(sku.toLowerCase());

const CELLULAR_XP_SKUS = [
  "note-nbnan",
  "note-nbgln",
  "note-mbnan",
  "note-mbgln",
  "note-wbnan",
  "note-wbexn",
];
export const isCellular_XP_SKU = (sku: string) =>
  CELLULAR_XP_SKUS.includes(sku.toLowerCase());

const V1_SKUS = [...CELLULAR_V1_SKUS, "note-wifi"];
export const is_V1_SKU = (sku: string) => V1_SKUS.includes(sku.toLowerCase());

const CELLULAR_NA_SKUS = [
  "note-nbna-500",
  "note-wbna-500",
  "note-nbnaw",
  "note-wbnaw",
  "note-nbnan",
  "note-wbnan",
  "note-mbnaw",
  "note-mbnan",
];
export const isCellular_NA_SKU = (sku: string) =>
  CELLULAR_NA_SKUS.includes(sku.toLowerCase());

const CELLULAR_GL_SKUS = [
  "note-nbgl-500",
  "note-nbglw",
  "note-wbglw",
  "note-wbglwt",
  "note-nbgln",
  "note-mbgln",
  "note-mbglw",
];
export const isCellular_GL_SKU = (sku: string) =>
  CELLULAR_GL_SKUS.includes(sku.toLowerCase());

const CELLULAR_SKUS = [
  ...CELLULAR_V1_SKUS,
  ...CELLULAR_V2_SKUS,
  ...CELLULAR_V3_SKUS,
  ...CELLULAR_V4_SKUS,
  ...CELLULAR_XP_SKUS,
];
export const isCellularSKU = (sku: string) =>
  CELLULAR_SKUS.includes(sku.toLowerCase());

const WIFI_ONLY_SKUS = ["note-wifi", "note-esp"];
export const isWiFiOnlySKU = (sku: string) =>
  WIFI_ONLY_SKUS.includes(sku.toLowerCase());

const WIFI_SKUS = [
  ...CELLULAR_V2_SKUS,
  ...WIFI_ONLY_SKUS,
  "note-mbnaw",
  "note-mbglw",
];
export const isSkuHasWiFi = (sku: string) =>
  WIFI_SKUS.includes(sku.toLowerCase());

const LORA_SKUS = ["note-lwus", "note-lweu"];
export const isLoRaSKU = (sku: string) => LORA_SKUS.includes(sku.toLowerCase());

export const isWideBandSKU = (sku: string) =>
  sku.toLowerCase().includes("note-wb");
export const isNarrowBandSKU = (sku: string) =>
  sku.toLowerCase().includes("note-nb");
export const isMidBandSKU = (sku: string) =>
  sku.toLowerCase().includes("note-mb");
export const isWBEXFamilySKU = (sku: string) =>
  sku.toLowerCase().includes("note-wbex");
export const isWBNAFamilySKU = (sku: string) =>
  sku.toLowerCase().includes("note-wbna");
export const isNBGLFamilySKU = (sku: string) =>
  sku.toLowerCase().includes("note-nbgl");
export const isNBNAFamilySKU = (sku: string) =>
  sku.toLowerCase().includes("note-nbna");
export const isMBGLFamilySKU = (sku: string) =>
  sku.toLowerCase().includes("note-mbgl");
export const isMBNAFamilySKU = (sku: string) =>
  sku.toLowerCase().includes("note-mbna");
