import { ReactNode, createContext } from "react";
import { Tabs } from "@components/doc/Tabs";
import theme from "@config/theme";

type ExampleRequestsContent = {
  children: ReactNode;
};

// Just so children can tell they're inside us.
export const ExampleRequestsContext = createContext(0);

const ExampleRequests = ({ children }: ExampleRequestsContent) => (
  <ExampleRequestsContext.Provider value={1 + Math.random()}>
    <div className="exampleRequests">
      <Tabs>{children}</Tabs>
    </div>
    <style jsx>{`
      .exampleRequests {
        margin-top: 1rem;
      }

      @media screen and (min-width: ${theme.breakpoints.lg}px) {
        // share the screen with the arguments.
        .exampleRequests,
        :global(.arguments) {
          box-sizing: border-box;
          float: left;
          width: 50%;
        }

        .exampleRequests {
          padding-left: 2rem;
          margin-top: 0;
        }
      }

      .exampleRequests :global(.navTabs) {
        margin: 0;
        border: 0;
      }

      .exampleRequests :global(.navTabs li) {
        padding: 0 8px;
        margin: 0.2rem 20px 0.2rem 0px;
      }

      .exampleRequests :global(.codeTabs) {
        margin: 1em 0px;
      }
    `}</style>
  </ExampleRequestsContext.Provider>
);

export default ExampleRequests;
