import { AuthContext } from "@adapters/AuthContextProvider";
import { useEffect, useContext } from "react";

const UPSCOPE_EMBED = "/js/upscope.js";

declare global {
  interface Window {
    Upscope: any;
  }
}

const Upscope = () => {
  const auth = useContext(AuthContext);
  const { userInfo: user } = auth;

  useEffect(() => {
    // Source: https://stackoverflow.com/a/34425083
    // License: https://creativecommons.org/licenses/by-sa/4.0/
    const script = document.createElement("script");

    script.src = UPSCOPE_EMBED;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    user.signedIn &&
      window.Upscope &&
      window.Upscope("updateConnection", {
        // Set the user ID below. If you don't have one, set to undefined.
        uniqueId: user.accountUID,

        // Set the user name or email below (e.g. ["John Smith", "john.smith@acme.com"]).
        identities: [user.email, user.name],
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.accountUID]);

  return null;
};

export default Upscope;
