/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/display-name */
import React, { useState, useEffect, useContext } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import classNames from "classnames";
import theme from "@config/theme";
import { ImgSrc } from "@config/image";
import { stringToURI } from "@scripts/utils";
import { AnalyticsGateway } from "@components/analytics/AnalyticsGateway";
import { LayoutContext } from "@components/layout/Layout";
import { BluesErrorBoundary } from "@components/BluesErrorBoundary";
import { MainMenuData, DocConfig } from "@config/nav";
import MobileTopNavMenu from "@components/layout/MobileTopNavMenu";
import { useWindowWidth } from "@react-hook/window-size";

export type MenuItem = {
  isGrayedOut?: boolean;
  level: number;
  selected: boolean;
  type: MenuItemType;
  title: string;
  subtitle?: string;
  items: MenuItem[];
  href?: string;
  onClick?: (event?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  docConfig?: DocConfig;
  open?: boolean;
  icon?: string;
  alias?: string;
  remainOpen?: boolean;
};

export type MenuItemType =
  | "main"
  | "galaxy"
  | "folder"
  | "doc"
  | "section"
  | "link";
export type PageType = "main" | "galaxy";

type MenuLinkProps = {
  item: MenuItem;
  selected: boolean;
  large?: boolean;
  bold?: boolean;
  color?: string;
  open?: boolean;
};

export const MenuLink = React.forwardRef(
  ({ item, selected, large, bold, ...props }: MenuLinkProps, ref: any) => (
    <div className="menu-item-container">
      <a
        {...props}
        className={classNames("menuHeader", { selected })}
        ref={ref}
      >
        {item.icon && <img className="icon" src={item.icon} alt="" />}
        <span>{item.alias || item.title}</span>
      </a>

      <style jsx>{`
        img,
        span {
          top: 0;
          bottom: 0;
          margin: auto;
          display: flex;
          align-items: center;
        }

        img {
          position: absolute;
          width: ${large ? "26px" : "16px"};
        }

        span {
          line-height: 1.15;
        }

        .menu-item-container {
          margin-right: -1rem;
          margin-left: ${item.level && item.level > 1
            ? `${item.level * -16}px`
            : "-1rem"}; /* handles calculations for expanding margin and padding for menu items and sub menu items 
            so they'll be properly indented and still highlight across the whole item's width on hover */
          padding-left: ${item.level && item.level > 1
            ? `${item.level * 24}px`
            : "1.25rem"};
          padding-right: 0.5rem;
          min-height: 42px;
          display: flex;
          align-items: center;
        }

        .menu-item-container:hover {
          background-color: ${theme.colors.devGray};
        }

        .menu-item-container .selected {
          background-color: ${theme.colors.ultramarineTintLightest};
          padding: 12px 8px 12px 32px;
          margin: 0 -1rem 0 -2rem;
          font-weight: 500;
        }

        .menuHeader {
          color: ${theme.colors.black};
          font-size: ${large ? theme.fonts.size.px24 : theme.fonts.size.px16};
          font-weight: ${item.level && item.level > 1 ? "400" : "500"};
          padding: ${item.type === "galaxy" ? "0.75rem 1rem " : "10px 1rem"};
          position: relative;
          margin: ${large ? "6px -1rem" : "0 -1rem"};
          flex-grow: 1;
        }
      `}</style>
    </div>
  )
);

// Left menu link titles
const MenuHeader = (props: MenuLinkProps) => (
  <Link href={props.item.href || "whoops"} passHref legacyBehavior>
    <MenuLink {...props} />
  </Link>
);

// Main Menu Items
export const MainMenu = () => {
  const { asPath } = useRouter();
  const basePath = asPath.replace("/", "");

  return (
    <>
      {MainMenuData.items.map((item) => (
        <MenuHeader
          key={item.title}
          item={item}
          selected={basePath === stringToURI(item.title)}
          bold
        />
      ))}
    </>
  );
};

// Go to Doc homepage
const BackHome = () => (
  <>
    <div className="back-home-link">
      <MenuHeader
        selected={false}
        item={{
          selected: false,
          level: 1,
          type: "galaxy",
          title: "Docs Home",
          href: "/docs",
          icon: ImgSrc("/images/icons/nav/chevron_left.svg"),
          items: [],
        }}
      />
    </div>
    <style jsx>{`
      .back-home-link,
      .back-home-link :global(.menu-item-container:hover) {
        margin: 0 -1rem;
        background-color: ${theme.colors.white};
      }

      .back-home-link span {
        margin-left: 1rem;
      }
      .back-home-link :global(.menuHeader img) {
        left: 1.25rem;
      }

      .back-home-link :global(.menuHeader span) {
        margin-left: 20px;
        color: ${theme.colors.black};
      }
    `}</style>
  </>
);

// Big link to galaxy as top of menu
const BigLinkGalaxyHome = () => {
  const currGalaxy = useRouter().query.galaxy;
  const galaxyItem = MainMenuData.items.find(
    (item) => stringToURI(item.title) === currGalaxy
  );
  return galaxyItem ? (
    <MenuHeader
      item={galaxyItem}
      bold
      large
      selected={false}
      key={galaxyItem.title}
    />
  ) : null;
};

// Galaxy Menu
type GalaxyMenuProps = { menus: MenuItem[] };
export const GalaxyMenu = ({ menus }: GalaxyMenuProps) => {
  const { menuCollapsed } = useContext(LayoutContext);
  return (
    <>
      <BackHome />
      <MobileTopNavMenu isOpen={!menuCollapsed} />
      <BigLinkGalaxyHome />
      {menus.map((menu) => (
        <LeftMenuItem menu={menu} key={menu.title} />
      ))}
    </>
  );
};

type LeftMenuProps = {
  menus: MenuItem[];
  type: PageType;
};

const LeftMenu = (props: LeftMenuProps) => {
  const {
    menuCollapsed,
    menuDocked,
    isWarningBannerShowing,
    isAnnouncementBannerShowing,
    setMenuCollapsed,
  } = useContext(LayoutContext);
  const windowWidth = useWindowWidth();
  const routeSlugs = useRouter().query.slugs;

  useEffect(() => {
    // no need to collapse the menu on larger screens
    if (windowWidth > theme.breakpoints.noReplMobile) {
      return;
    }

    // collapse menu on mobile when a menu item is selected
    setMenuCollapsed(true);
  }, [routeSlugs]);

  return (
    <BluesErrorBoundary boundaryName="LeftMenu">
      <nav
        className={
          "page-menu" +
          `${menuCollapsed ? " menuCollapsed" : ""}` +
          `${!menuDocked ? " menuTray" : " menuDocked"}` +
          `${isWarningBannerShowing ? " warningBanner" : ""}` +
          `${isAnnouncementBannerShowing ? " visible-banner" : ""}`
        }
      >
        <div className="menusContainer">
          {props.type === "main" && (
            <MobileTopNavMenu isOpen={!menuCollapsed} />
          )}

          {props.type === "main" && <MainMenu />}

          {props.type === "galaxy" && <GalaxyMenu menus={props.menus} />}
        </div>

        <style jsx>{`
          .page-menu {
            overflow: hidden auto;
            -ms-overflow-style: none;
            display: flex;
            flex-direction: column;
            line-height: 1.2;
            padding: 0 1rem;
            position: fixed;
            top: ${theme.header.height};
            z-index: ${theme.zIndex.menu};
            grid-area: menu;
            height: 100%;
            width: ${theme.menu.width};
            background-color: ${theme.colors.gray5};
            border-right: 1px solid ${theme.colors.gray4};
            container-name: side-menu;
            container-type: inline-size;
          }

          .page-menu.menuCollapsed {
            left: -${theme.menu.width};
          }

          // announcement banner displayed
          .page-menu.menuDocked.visible-banner {
            top: calc((${theme.header.height} + 33px));
          }

          .menusContainer {
            padding-bottom: 6rem;
            flex: 1;
          }

          .page-menu.menuDocked.visible-banner :global(.menusContainer),
          .page-menu.menuTray.visible-banner :global(.menusContainer) {
            padding-bottom: 10rem;
          }

          .menusContainer a,
          .menuHeader {
            color: ${theme.colors.black};
          }

          // styling required for the newer mobile top nav menu to integrate well with the existing docs menu
          .menusContainer :global(.mobile-top-nav-menu-wrapper) {
            position: relative;
            display: none;
            height: auto;
            padding-bottom: 0.5rem;
            border-bottom: 1px solid ${theme.colors.black};
          }

          .menusContainer :global(.mobile-top-nav-item) {
            margin-right: -1rem;
            margin-left: -2.5rem;
            padding-left: 1rem;
          }

          .menusContainer :global(.dropdown-title) {
            font-weight: normal;
          }
          .menusContainer :global(.dropdown-subtitle) {
            display: none;
          }

          .menusContainer
            :global(
              .mobile-top-nav-item .tabContainer span:not(.tabTitle, .arrow)
            ) {
            margin-left: 28px;
          }

          // hide "Docs" link from main galaxy side menu for now
          .menusContainer :global(.mobile-top-nav-item.Docs) {
            display: none;
          }

          @media screen and (max-width: ${theme.breakpoints
              .condenseTopNavBar}px) {
            .menusContainer :global(.mobile-top-nav-menu-wrapper) {
              display: block;
            }

            .menusContainer :global(.mobile-top-nav-item .badge) {
              top: 5px;
            }
          }

          @container side-menu (max-width: ${theme.menu.width}) {
            .menusContainer :global(.mobile-top-nav-item) {
              margin-left: -1rem;
            }

            .menusContainer
              :global(
                .mobile-top-nav-item .tabContainer span:not(.tabTitle, .arrow)
              ) {
              margin-left: 0;
            }

            .menusContainer :global(.tabContainer .dropdown-link) {
              margin-left: -1rem;
              padding: 0.5rem 2rem;
              height: unset;
            }

            .menusContainer :global(.mobile-top-nav-item .badge) {
              left: 96px;
            }
          }

          // smallest size repl allowed 600px => 991px
          @media screen and (min-width: ${theme.breakpoints
              .noReplMobile}px) and (max-width: ${theme.breakpoints.md}px) {
            :global(.page-layout.replFullSize .page-menu.menuDocked),
            :global(.page-layout.replCollapsed .page-menu.menuDocked) {
              height: 100%;
              display: block;
            }

            .page-menu.menuDocked.visible-banner {
              top: calc(
                (
                  ${theme.header.height} +
                    ${theme.announcementBanner.height.mediumScreen}
                )
              );

              height: calc(
                100% -
                  (
                    ${theme.header.height} +
                      ${theme.announcementBanner.height.mediumScreen}
                  )
              );
            }
          }

          @media screen and (min-width: ${theme.breakpoints
              .sm}px) and (max-width: ${theme.breakpoints.noReplMobile}px) {
            .page-menu.menuTray {
              height: calc(100% - ${theme.header.height});
            }

            .page-menu.menuTray.visible-banner {
              top: calc(
                (
                  ${theme.header.height} +
                    ${theme.announcementBanner.height.mediumScreen}
                )
              );
              height: calc(
                100% - ${theme.header.height} +
                  ${theme.announcementBanner.height.mediumScreen}
              );
            }

            // warning banner displayed
            .page-menu.menuTray.warningBanner {
              top: calc(${theme.header.height} + ${theme.banner.height});
              height: calc(
                100% - ${theme.header.height} + ${theme.banner.height}
              );
            }

            // announcement & warning banner displayed
            .page-menu.menuTray.warningBanner.visible-banner {
              top: calc(
                (${theme.header.height}) + ${theme.banner.height} +
                  ${theme.announcementBanner.height.mediumScreen}
              );
              height: calc(
                100% -
                  (
                    (${theme.header.height}) + ${theme.banner.height} +
                      ${theme.announcementBanner.height.mediumScreen}
                  )
              );
            }
          }

          // screen sizes below 480px
          @media screen and (max-width: ${theme.breakpoints.sm}px) {
            .page-menu {
              width: 100%;
            }

            .page-menu.menuCollapsed {
              left: -100%;
            }

            .page-menu.menuTray.visible-banner {
              top: calc(
                ${theme.header.height} +
                  ${theme.announcementBanner.height.smallScreen}
              );
            }

            // announcement & warning banner displayed
            .page-menu.menuTray.warningBanner.visible-banner {
              top: calc(
                (${theme.header.height}) + ${theme.banner.height} +
                  ${theme.announcementBanner.height.smallScreen}
              );
              height: calc(
                100% -
                  (
                    (${theme.header.height}) + ${theme.banner.height} +
                      ${theme.announcementBanner.height.smallScreen}
                  )
              );
            }
          }
        `}</style>
      </nav>
    </BluesErrorBoundary>
  );
};

// observer variables to detect what document section is visible on screen
let observer: IntersectionObserver;
export const observeElements = (queryStr: string) => {
  if (observer)
    document.querySelectorAll(queryStr).forEach((ele) => observer.observe(ele));
};

let ignoreBoundaryChanges = true;
let firstLoad = true;

const disconnectObserver = () => {
  if (observer) {
    observer.disconnect();
  }
};

// Pages that change how <h2> elements render cannot use the scroll-based
// “selecting” of submenus in the navigation.
const shouldSelectSubMenus = (href: string) => {
  const pagesThatDoNotHighlightSubMenus = [
    "/quickstart/cellwifi-quickstart",
    "/quickstart/lorawan-quickstart",
  ];
  return !pagesThatDoNotHighlightSubMenus.includes(href);
};

export const DocMenu = ({
  level,
  selected,
  type,
  title,
  alias,
  href,
  items,
}: MenuItem) => {
  const routeSlugs = useRouter().query.slugs;
  const [selectedItem, selectItem] = useState(items[0]?.title);
  const { setMenuCollapsed } = useContext(LayoutContext);
  const windowWidth = useWindowWidth();

  useEffect(() => {
    const onScroll = () => {
      if (
        selected &&
        window.innerHeight + window.scrollY >= document.body.offsetHeight
      ) {
        selectItem(items[items.length - 1]?.title);
      }
    };

    if (selected) {
      window.addEventListener("scroll", onScroll);
    }

    return () => window.removeEventListener("scroll", onScroll);
  }, [selected]);

  useEffect(() => () => disconnectObserver(), []);

  const onObserverChange = (changes: IntersectionObserverEntry[]) => {
    if (!firstLoad && !ignoreBoundaryChanges) {
      changes.forEach((change) => {
        // select section when it scrolls INTO bottom boundary
        if (change.intersectionRatio > 0) {
          if (change.intersectionRect.y > 0) {
            // find element in items by title
            const hdrTitle = items.find(
              (item) => stringToURI(item.title) === change.target.id
            )?.title;

            // glossary uses html H elements instead of items
            if (hdrTitle || stringToURI(title) === "glossary") {
              // set url hash

              AnalyticsGateway().trackPageContext({
                sectionID: change.target.id,
              });

              // select item if exists
              if (hdrTitle) {
                selectItem(hdrTitle);
              }
            }
          }
        } else {
          // select prev section when a section scrolls OUT OF bottom boundary
          // eslint-disable-next-line no-lonely-if
          if (
            change.intersectionRect.y <= 0 &&
            change.boundingClientRect.y > 0
          ) {
            const idx = items.findIndex(
              (item) => stringToURI(item.title) === change.target.id
            );

            // element id is a section title in items, select prev item
            if (idx > 0) {
              AnalyticsGateway().trackPageContext({
                sectionID: stringToURI(items[idx - 1].title),
              });
              selectItem(items[idx - 1].title);
            }
            // glossary uses html H elements
            else if (stringToURI(title) === "glossary") {
              const docHeaders = document.querySelectorAll(
                ".doc h1, h2, h3, h4, h5, h6"
              );

              docHeaders.forEach((h, i) => {
                if (h.id === change.target.id) {
                  AnalyticsGateway().trackPageContext({
                    sectionID: docHeaders[i - 1].id,
                  });
                }
              });
            }
          }
        }
      });
    }
    if (firstLoad) {
      // on load: select left menu title according to url hash id.
      // if the hash id is not a section title on the left menu,
      //  it's a child of the previous section title, so use that
      const docHeaders = document.querySelectorAll(
        ".doc h1 > span, h2 > span, h3 > span, h4 > span, h5 > span, h6 > span"
      );
      let isMenuTitle = false;
      let eleIdx = -1;

      // loop through all document headers and check if url hash id is a left menu section title
      docHeaders.forEach((ele, idx) => {
        if (ele.id && ele.id === window.location.hash.replace("#", "")) {
          eleIdx = idx;
          if (items.find((item) => stringToURI(item.title) === ele.id)) {
            isMenuTitle = true;
          }
        }
      });

      // if it's not a menu title (which is set to false by default), it's a child of the previous title
      if (isMenuTitle && eleIdx > -1) {
        for (let i = eleIdx; i > 0; i -= 1) {
          const title2 = items.find(
            (item) => stringToURI(item.title) === docHeaders[i].id
          )?.title;
          if (title2) {
            i = 0;
            selectItem(title2);
          }
        }
      }

      // set flag
      firstLoad = false;
    } else if (ignoreBoundaryChanges) {
      ignoreBoundaryChanges = false;
    }
  };

  const initSectionObserver = () => {
    // disconnect observer first
    disconnectObserver();

    // set bottom boundary upwards 65% of window height
    const config = {
      rootMargin: "0% 0% -65% 0%",
    };

    observer = new IntersectionObserver(onObserverChange, config);
    // observeElements is called within the H2 component for MDX documents
  };

  useEffect(() => {
    if (selected) {
      firstLoad = true;
      initSectionObserver();
      ignoreBoundaryChanges = false;
    }
  }, [routeSlugs]);

  // needed for all pages with sub submenus
  useEffect(() => {
    // no need to collapse the menu on larger screens
    if (windowWidth > theme.breakpoints.noReplMobile) {
      return;
    }

    // collapse menu on mobile when a menu item is selected
    setMenuCollapsed(true);
  }, [selectedItem]);

  type DocItemProps = {
    item: MenuItem;
    selected: boolean;
  };

  const DocItem = ({ item, selected: selected2 }: DocItemProps) => (
    <a
      className={classNames("linkSection", { selectedSection: selected2 })}
      href={`#${stringToURI(item.title)}`}
    >
      <span>{item.alias || item.title}</span>
      <style jsx>{`
        .linkSection {
          display: flex;
          align-items: center;
          padding-left: ${item.level && item.level > 2
            ? `${item.level * 5}px`
            : "15px"};
          padding-right: ${item.level && item.level > 2
            ? `${item.level * 5.5}px`
            : "16px"};
          font-weight: 400;
          color: ${theme.colors.black};
          cursor: pointer;
          margin-left: ${item.level && item.level > 2
            ? `${item.level * 5}px`
            : "0px"};
          margin-right: ${item.level && item.level > 2
            ? `${item.level * -5.5}px`
            : "-16px"};
          font-size: ${theme.fonts.size.px14};
        }

        .linkSection::before {
          content: "";
          display: inline-block;
          height: 45px;
          position: relative;
          left: -15px;
          border-left: 1.5px solid ${theme.colors.gray4};
        }

        .linkSection.selectedSection {
          font-weight: 500;
        }

        .linkSection.selectedSection::before {
          content: "";
          display: inline-block;
          height: 45px;
          position: relative;
          left: -1rem;
          border-left: 3px solid ${theme.colors.ultramarineTint};
        }

        .linkSection:hover {
          color: ${theme.colors.black};
          background-color: ${theme.colors.devGray};
          overflow: visible;
        }

        .linkSection > span {
          hyphens: auto;
        }
      `}</style>
    </a>
  );

  // We used to have code here that tried to copy useful slugs/parameters from
  // useRouter().query (which looked like ["sensor-tutorial", "notecarrier-al",
  // "arduino", "circuitpython"]) but the code didn't really work
  // esp. when navigating from one page to another if they keep their parameters
  // in a different order.
  return (
    <div className="container">
      <MenuHeader
        selected={selected}
        item={{ selected, level, type, title, alias, href, items }}
      />

      {selected &&
        stringToURI(title) !== "glossary" &&
        items.map((item) => (
          <DocItem
            item={item}
            key={item.title}
            selected={
              selectedItem === item.title && shouldSelectSubMenus(href || "")
            }
          />
        ))}
      <style jsx>{`
        .container {
          display: flex;
          flex-direction: column;
        }
      `}</style>
    </div>
  );
};

// Left Menu Core Items
type LeftMenuItemProps = { menu: MenuItem };
const LeftMenuItem = (props: LeftMenuItemProps) => {
  const getMenuItem = (menu: MenuItem, items: React.ReactNode[]) => {
    // add doc or folder menu to menu items
    items.push(
      menu.type === "doc" ? (
        <DocMenu key={menu.title} {...menu} />
      ) : (
        <MenuHeader
          key={menu.title}
          item={menu}
          selected={menu.selected}
          open={!!menu.items.find((item) => item.selected)}
        />
      )
    );

    // get submenu items if not a doc.
    // a doc menu has its own submenu items
    if (menu.type !== "doc") {
      menu.items.forEach((item) =>
        getMenuItem(item, []).forEach((component) => items.push(component))
      );
    }

    return items;
  };

  return <>{getMenuItem(props.menu, [])}</>;
};

export default LeftMenu;
