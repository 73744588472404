import { useContext } from "react";
import theme from "@config/theme";
import { getWindowSize, stringToURI } from "@scripts/utils";
import { ImgSrc } from "@config/image";
import { LayoutContext } from "@components/layout/Layout";
import { BluesErrorBoundary } from "@components/BluesErrorBoundary";
import { TrackedLink } from "@components/analytics/TrackedLink";
import Upscope from "@components/upscope/Upscope";
import WarningBanner, { WarningBannerProps } from "@components/WarningBanner";
import AnnouncementBanner from "@components/AnnouncementBanner";
import BannerContentComponent from "@components/BannerContentComponent";
import { MenuItem } from "@components/layout/Menu";
import { Tab } from "@components/menu/Tab";
import { SearchBox } from "@components/search/SearchBox";
import { HeaderMenuData } from "@config/nav";
import { UserMenu } from "@components/menu/UserMenu";
import { WhatsNew } from "@components/menu/WhatsNew";
import ResourcesMenu from "@components/menu/ResourcesMenu";
import SupportMenu from "@components/menu/SupportMenu";
import CloseButtonIcon from "@components/CloseButtonIcon";
import MobileMenuIcon from "@components/MobileMenuIcon";
import OpenSupportWidget from "@adapters/ZendeskSupportWidget";

export const quickStartWarningData: WarningBannerProps = {
  title: "We noticed you're on a mobile device.",
  desc: "To get the most out of this guide, you'll want to follow along on a computer with a USB connection and Google Chrome.",
  isHidden: false,
};

export const Logo = () => (
  <div className="logoContainer">
    <TrackedLink href="/">
      <img
        className="imgLogo"
        src={ImgSrc("/images/logo/blues-logomark-white.svg")}
        alt="Blues Developers"
      />
    </TrackedLink>
    <style jsx>{`
      .logoContainer {
        z-index: ${theme.zIndex.logo};
        padding: 0.75rem 1rem;
        width: 200px;
        flex-grow: 1;
      }

      .imgLogo {
        height: 75%;
        margin-top: 4px;
      }

      @media screen and (max-width: ${theme.breakpoints.condenseTopNavBar}px) {
        .logoContainer {
          width: 152px;
          padding-left: 0rem;
        }
      }

      @media screen and (max-width: ${theme.breakpoints.sm}px) {
        .logoContainer {
          width: 85px;
        }
      }
    `}</style>
  </div>
);

type MobileTopNavMenuToggleProps = {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
};

// for top nav menu on tablet/mobile (below 870px wide)
export const MobileTopNavMenuToggle = ({
  isOpen,
  setIsOpen,
}: MobileTopNavMenuToggleProps) => {
  const toggleMobileTopNavMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className="mobileTopNavMenuIconContainer"
      onClick={() => toggleMobileTopNavMenu()}
    >
      {!isOpen ? <MobileMenuIcon /> : <CloseButtonIcon />}

      <style jsx>
        {`
          .mobileTopNavMenuIconContainer {
            width: 56px;
            color: ${theme.colors.white};
            display: none; // this gets set to display: flex by the SiteChrome.tsx file
            align-items: center;
            cursor: pointer;
            padding: 0 1rem;
          }
        `}
      </style>
    </div>
  );
};

type MenuToggleProps = {
  collapsed: boolean;
  setCollapsed: (val: boolean) => void;
  docked: boolean;
  setDocked: (val: boolean) => void;
};

// for docs menu pages
export const MenuToggle = ({
  collapsed,
  setCollapsed,
  docked,
  setDocked,
}: MenuToggleProps) => {
  const menuToggleClick = () => {
    const size = getWindowSize();

    if (
      size.width &&
      size.width > theme.breakpoints.noReplMobile &&
      size.width <= theme.breakpoints.md
    ) {
      if (!docked) {
        setDocked(true);
      }
    }

    setCollapsed(!collapsed);
  };

  return (
    <div className="menuIconContainer" onClick={() => menuToggleClick()}>
      {collapsed ? <MobileMenuIcon /> : <CloseButtonIcon />}

      <style jsx>{`
        .menuIconContainer {
          width: 56px;
          color: ${theme.colors.white};
          display: flex;
          align-items: center;
          cursor: pointer;
          padding: 0 1rem;
        }

        @media screen and (min-width: ${theme.breakpoints
            .condenseTopNavBar}px) {
          .menuIconContainer {
            display: none;
          }
        }
      `}</style>
    </div>
  );
};

type HeaderProps = {
  selectedPage: string;
};

const Header = ({ selectedPage }: HeaderProps) => {
  const {
    menuCollapsed,
    setMenuCollapsed,
    menuDocked,
    setMenuDocked,
    isWarningBannerShowing,
    setIsWarningBannerShowing,
    isAnnouncementBannerShowing,
    isMobileTopNavMenuOpen,
    setIsMobileTopNavMenuOpen,
  } = useContext(LayoutContext);

  const closeWarningBanner = () => {
    setIsWarningBannerShowing(false);
  };

  return (
    <nav className="page-header">
      <BluesErrorBoundary boundaryName="Header">
        <AnnouncementBanner>
          <BannerContentComponent />
        </AnnouncementBanner>
        {isWarningBannerShowing ? (
          <WarningBanner
            title={quickStartWarningData.title}
            desc={quickStartWarningData.desc}
            onCloseClick={closeWarningBanner}
            isHidden={!isWarningBannerShowing}
          />
        ) : null}
        <div className="toolbarContainer">
          <MobileTopNavMenuToggle
            isOpen={isMobileTopNavMenuOpen}
            setIsOpen={(val) => setIsMobileTopNavMenuOpen(val)}
          />
          <MenuToggle
            collapsed={menuCollapsed}
            setCollapsed={(val) => setMenuCollapsed(val)}
            docked={menuDocked}
            setDocked={(val) => setMenuDocked(val)}
          />

          <Logo />

          <div className="tabContainerDesktop">
            <WhatsNew />
            <ResourcesMenu />

            {HeaderMenuData.map((item: MenuItem) => (
              <Tab
                {...item}
                key={stringToURI(item.title)}
                open={selectedPage === stringToURI(item.title)}
              />
            ))}
          </div>
          <div className="searchMobile">
            <SearchBox />
          </div>
          <SupportMenu onClick={OpenSupportWidget} />
          <div className="searchDesktop">
            <SearchBox />
          </div>
          <UserMenu />
        </div>
        <Upscope />
      </BluesErrorBoundary>

      <style jsx>{`
        .page-header {
          grid-area: header;
          position: sticky;
          top: 0;
          background-color: ${theme.colors.black};
          z-index: ${theme.zIndex.header};
        }

        .toolbarContainer {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          height: ${theme.header.height};
        }

        :global(.anchor-scroll-target) {
          padding-top: var(--page-header-height);
          margin-top: calc(-1 * var(--page-header-height));
          scroll-margin-top: ${isAnnouncementBannerShowing
            ? `${theme.announcementBanner.height.largeScreen}`
            : `5px`};
        }

        .tabContainerDesktop,
        .searchDesktop {
          display: flex;
          align-items: center;
        }

        .searchMobile {
          display: none;
        }

        .search {
          padding: var(--search-padding);
        }

        @media screen and (max-width: ${theme.breakpoints.lg}px) {
          :global(.anchor-scroll-target) {
            scroll-margin-top: ${isAnnouncementBannerShowing
              ? `${theme.announcementBanner.height.mediumScreen}`
              : `0px`};
          }
        }

        @media screen and (max-width: ${theme.breakpoints
            .condenseTopNavBar}px) {
          .tabContainerDesktop,
          .searchDesktop {
            display: none;
          }

          .searchMobile {
            display: flex;
            align-items: center;
            padding-right: 1rem;
          }
        }

        @media screen and (max-width: ${theme.breakpoints.noReplMobile}px) {
          :global(.anchor-scroll-target) {
            scroll-margin-top: ${isAnnouncementBannerShowing
              ? `230px`
              : `${theme.banner.height}`};
          }
        }

        @media screen and (max-width: ${theme.breakpoints.sm}px) {
          :global(.anchor-scroll-target) {
            /*  I cannot figure out a way with styled JSX to check if both announcement 
            and warning banners are showing or one or neither so I'm going to check for announcement, 
            scroll as if warning banner is present and if neither is visible,
            the scroll to top will just have a little more margin than before */
            scroll-margin-top: ${isAnnouncementBannerShowing
              ? `${theme.announcementBanner.height.smallScreen}`
              : `100px`};
          }
        }
      `}</style>
    </nav>
  );
};

export default Header;
export { Tab };
