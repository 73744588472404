import { Config } from "@configured/EnvironmentConfig";
import Router from "next/router";

const debug = false;
const debugLog = debug ? console.log : () => {}; // eslint-disable-line no-console

export const DocSearchNavigatorForNextJS = {
  navigate({ itemUrl }: { itemUrl: string }) {
    debugLog("navigate!", itemUrl);
    const url = itemUrl.replace(Config.siteURL, "");
    const urlIsAbsolute = url.indexOf(`://`) !== -1;
    if (urlIsAbsolute) {
      // link doesn't match current domain, open in new tab
      debugLog("navigateNewTab!", url);
      DocSearchNavigatorForNextJS.navigateNewTab({ itemUrl });
    } else {
      // link matches current domain, use NextJS router
      debugLog("navigateNextJS!", url);
      Router.push(url);
    }
  },
  navigateNewTab({ itemUrl }: { itemUrl: string }) {
    const windowReference = window.open(itemUrl, "_blank", "noopener");

    if (windowReference) {
      windowReference.focus();
    }
  },
  navigateNewWindow({ itemUrl }: { itemUrl: string }) {
    window.open(itemUrl, "_blank", "noopener");
  },
};
