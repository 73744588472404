import React, { useEffect } from "react";
import theme from "@config/theme";
import { useRouter } from "next/router";
import { stringToURI } from "@scripts/utils";
import { observeElements } from "@components/layout/Menu";

type HeadingProps = {
  priority: number;
  children: React.ReactNode;
  target?: string;
};

const Heading = ({ priority, children, target }: HeadingProps) => {
  const router = useRouter();
  const route = router.query;

  useEffect(() => {
    // make sure it's a string and observe this element to update left navigation and url using id
    if (target) {
      observeElements(`#${target}`);
    }
  }, [route.slugs]);

  // set anchor target if prop not set
  const setTarget = () => {
    if (!target) {
      // children is array when multiple elements used ex) ## Test Title <Badge/>
      // use only strings from array
      // TODO: use element text ex) # Test Title `Bar` => test-title-bar
      if (Array.isArray(children)) {
        target = children
          .filter((c) => typeof c === "string" && c.trim())
          .map((c) => (c as string).trim())
          .join("-");
      } else if (typeof children === "string") {
        target = children;
      }
    }

    // convert target string to key format
    target = target ? stringToURI(target) : "";
  };

  // set target
  setTarget();

  // If we're the active #hash then scroll down to us.
  useEffect(() => {
    if (!target) return;
    if (!window.location.hash) return;
    const id = window.location.hash.substring(1);
    if (target != id) return;
    const element = document.getElementById(target);
    if (!element) return;

    // (I hate this hack, but the glossary re-renders 50+ times, the REPL might
    // be about to collapse, and who knows what else. My react skills aren't up
    // to the task of waiting elegantly for the end of all that.)
    //
    // Pause to give other elements below us time to load so when we try to
    // scroll into view the browser can put us right at the top of the screen.
    // Otherwise, we might be the last element on the page at the time of scroll
    // and that'd make us get stuck near the bottom of the screen. Or we get put
    // at the top of the screen but then the REPL collapses, text reflows, and
    // we end up scrolled up past the top of the page.
    //
    // Scroll multiple times so we can scroll asap for fast computers and as
    // late as possible for slow computer.
    element.scrollIntoView();
    setTimeout(() => element.scrollIntoView(), 100 /* ms */);
    setTimeout(() => element.scrollIntoView(), 300 /* ms */);
    setTimeout(() => element.scrollIntoView(), 1000 /* ms */);
    setTimeout(() => element.scrollIntoView(), 2000 /* ms */);
  }, [target]);

  return (
    <>
      <div className="floatfollower" />
      {(priority > 5 || !target) &&
        React.createElement(`h${priority}`, null, children)}
      {priority <= 5 &&
        target &&
        React.createElement(
          `h${priority}`,
          { className: "no-pointer-events" },
          <span className="anchor-scroll-target no-pointer-events" id={target}>
            <a href={`#${target}`} className="auto-pointer-events">
              {children}
              <svg
                className="anchor-icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z" />
              </svg>
              <svg
                className="hash-icon"
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
                fill="currentColor"
              >
                <path d="M287-160q-14 0-23-11t-5-25l34-134H178q-14 0-23.5-11.5T149-367q2-10 10-16.5t19-6.5h130l45-180H218q-14 0-23.5-11.5T189-607q2-10 10-16.5t19-6.5h150l37-148q2-10 9.5-16t17.5-6q14 0 22.5 11t5.5 25l-33 134h181l37-148q2-10 9.5-16t17.5-6q14 0 22.5 11t5.5 25l-33 134h115q14 0 23.5 11.5T811-593q-2 10-10 16.5t-19 6.5H652l-45 180h135q14 0 23.5 11.5T771-353q-2 10-10 16.5t-19 6.5H592l-37 148q-2 8-8.5 15t-19.5 7q-14 0-23-11t-5-25l34-134H352l-37 148q-2 8-8.5 15t-19.5 7Zm80-230h181l45-180H412l-45 180Z" />
              </svg>
            </a>
          </span>
        )}

      <style jsx>{`
        :global(.no-pointer-events) {
          pointer-events: none;
        }

        :global(.no-pointer-events) .auto-pointer-events {
          pointer-events: auto;
        }

        .anchor-scroll-target {
          display: block; // act like a div while allowing html validation
        }
        // Force a break clear across the document
        .floatfollower {
          box-sizing: border-box;
          clear: both;
          width: 100%;
        }
        // todo (paige) clean this file up when we swap over to all hash links in the repo
        .anchor-scroll-target a,
        .anchor-scroll-target a:hover,
        .anchor-scroll-target a:visited {
          color: ${theme.colors.black};
          display: inline-block;
          cursor: pointer;
        }

        svg {
          visibility: hidden;
        }
        a:hover .anchor-icon {
          visibility: visible;
        }

        .hash-icon {
          display: none;
        }

        svg {
          display: inline-block;
          vertical-align: middle;
          padding: 0 0.1rem;
          color: ${theme.colors.ultramarineTint};
        }

        svg:hover {
          color: ${theme.colors.ultramarine};
        }
      `}</style>
    </>
  );
};

type TitleProps = {
  children: React.ReactNode;
};

export const Title1 = ({ children }: TitleProps) => (
  <Heading priority={1}>{children}</Heading>
);
export const Title2 = ({ children }: TitleProps) => (
  <Heading priority={2}>{children}</Heading>
);
export const Title3 = ({ children }: TitleProps) => (
  <Heading priority={3}>{children}</Heading>
);
export const Title4 = ({ children }: TitleProps) => (
  <Heading priority={4}>{children}</Heading>
);
export const Title5 = ({ children }: TitleProps) => (
  <Heading priority={5}>{children}</Heading>
);
export const Title6 = ({ children }: TitleProps) => (
  <Heading priority={6}>{children}</Heading>
);
export const Subtitle = ({ children }: TitleProps) => (
  <>
    <br />
    <small>
      <small>
        <span style={{ display: "none" }}>-</span>
        {children}
      </small>
    </small>
    <style jsx>{`
      * {
        // Normal font weight
        font-weight: normal;
      }
    `}</style>
  </>
);

export default Heading;
