/* eslint-disable no-underscore-dangle */
import "@docsearch/css";
import { DocSearch } from "@docsearch/react";
import Head from "next/head";

import theme from "@config/theme";
import { Config } from "@configured/EnvironmentConfig";
import { DocSearchNavigatorForNextJS } from "./DocSearchNavigatorForNextJS";
import { ResultLink } from "./ResultLink";

export const SearchBox = (_props = {}) => (
  <>
    <div className="search-button">
      <Head>
        <link
          rel="preconnect"
          href={`https://${Config.algoliaAppId}-dsn.algolia.net`}
          crossOrigin="anonymous"
        />
      </Head>
      <DocSearch
        apiKey={Config.algoliaApiKey}
        appId={Config.algoliaAppId}
        hitComponent={ResultLink}
        indexName={Config.algoliaIndexName}
        insights
        navigator={DocSearchNavigatorForNextJS}
        translations={{ button: { buttonText: "Search..." } }}
      />
    </div>
    <style jsx>
      {`
        :global(.DocSearch-Button) {
          border-radius: 5px;
          margin: 0;
        }

        // Search Results Modal Style
        :global(.DocSearch-Logo) {
          visibility: hidden;
        }

        .searchContainer :global(input::placeholder) {
          color: ${theme.colors.white};
        }

        // overriding Algolia default styling that condenses the search box on screens under 768px
        @media screen and (max-width: ${theme.breakpoints
            .condenseTopNavBar}px) {
          :global(.DocSearch-Button-Placeholder),
          :global(.DocSearch-Button-Keys) {
            display: none;
          }

          :global(.DocSearch-Button) {
            background-color: transparent;
          }

          :global(.DocSearch-Button .DocSearch-Search-Icon) {
            color: ${theme.colors.white};
          }

          :global(.DocSearch-Button:hover .DocSearch-Search-Icon) {
            color: ${theme.colors.black};
          }
        }
      `}
    </style>
  </>
);
const DEFAULT = { SearchBox };
export default DEFAULT;
