import theme from "@config/theme";

type TagsProps = {
  tags: { name: string; url?: string }[];
};

const Tags = ({ tags }: TagsProps) => (
  <>
    <ul className="tags">
      {tags.map((tag) => (
        <li key={tag.name}>
          {tag.url ? <a href={tag.url}>{tag.name}</a> : <>{tag.name}</>}
        </li>
      ))}
    </ul>
    <style jsx>
      {`
        ul {
          list-style: none;
          display: inline;
          padding: 0;
        }
        .tags {
          display: flex;
          flex-wrap: wrap;
          row-gap: 10px;
          margin: 1rem 0;
        }
        .tags li {
          padding: 0.32rem 0.5rem;
          margin: 0 0.5rem 0 0;
          font-size: ${theme.fonts.size.px14};
          line-height: 1;
          white-space: nowrap;
          border-radius: 4px;
          background-color: ${theme.colors.devGray};
        }
        .tags a {
          color: ${theme.colors.black};
        }
        .tags a:hover {
          color: ${theme.colors.ultramarine};
        }
      `}
    </style>
  </>
);

export default Tags;
