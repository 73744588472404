import { useRouter } from "next/router";
import DropDownButton from "@components/DropDownButton";
import { TrackedLink } from "@components/analytics/TrackedLink";
import { MenuItem } from "@components/layout/Menu";
import theme from "@config/theme";

const Tab = ({
  title,
  alias,
  href,
  onClick,
  open,
  items,
  icon,
  remainOpen,
}: MenuItem) => {
  const router = useRouter();

  const dropDownItemClick = (item: MenuItem) => {
    item.onClick?.();
    if (!item.href) {
      // noop
    } else if (item.href.startsWith("http")) {
      window.open(item.href);
    } else {
      router.push(item.href);
    }
  };

  return (
    <div className="tabContainer">
      {items.length ? (
        <DropDownButton
          btnClickableElement={
            <a className="tabLink">
              <span className={open ? "selected" : ""}>
                {icon && (
                  <img src={icon} alt="Button Icon" className="imgIcon" />
                )}
                <span className="tabTitle">{alias || title}</span>
                {items.length > 0 && <span className="arrow" />}
              </span>
            </a>
          }
          id={title}
          items={items}
          onChange={(child) => dropDownItemClick(child)}
          remainOpen={remainOpen}
        />
      ) : (
        <TrackedLink
          data-analytics-location="header"
          href={href}
          onClick={onClick}
          className="tabLink"
        >
          {icon && <img src={icon} alt="Button Icon" className="imgIcon" />}
          <span>{alias || title}</span>
        </TrackedLink>
      )}
      <style jsx>{`
        .tabContainer {
          white-space: nowrap;
        }

        .tabContainer :global(.dropdown-content) {
          margin: -0.5rem 0 0 -1.5rem;
        }

        .tabContainer :global(.tabLink) {
          height: ${theme.header.height};
          display: flex;
          padding: 0 1rem;
          cursor: pointer;
          justify-content: center;
        }

        .tabContainer :global(.tabLink:hover) {
          text-decoration: none;
        }

        .tabContainer :global(.tabLink span) {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: ${theme.fonts.size.px18};
          color: ${theme.colors.white};
        }

        .tabContainer :global(.tabLink:hover span) {
          color: ${theme.colors.ultramarineTintLighter};
        }

        .tabContainer :global(.tabLink .arrow) {
          border-top: 5px solid ${theme.colors.white};
        }

        .imgIcon {
          display: none;
          height: 100%;
          padding: 0.75rem 0 0.75rem 0.75rem;
        }

        .tabContainer :global(.tabLink span .tabTitle) {
          display: inline-block;
        }

        @media screen and (max-width: ${theme.breakpoints.md}px) {
          .tabContainer :global(.tabLink) {
            padding: 0 0.5rem;
          }
        }

        @media screen and (max-width: ${theme.breakpoints.sm}px) {
          .tabContainer :global(.tabLink) {
            padding: 0;
          }

          .tabContainer :global(.tabLink span .tabTitle) {
            display: none;
          }

          .imgIcon {
            display: block;
          }
        }
      `}</style>
    </div>
  );
};

const forceNamedExport = {};
export default forceNamedExport;
export { Tab };
