import { useState } from "react";
import { Tooltip } from "react-tooltip";
import theme from "@config/theme";
import CheckMark from "@components/Checkmark";

const CopyLink = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#F8FAFC">
    <g id="Group_9214" transform="translate(-5322 -966)">
      <g id="Group_9212" transform="translate(5322 966)">
        <rect id="Rectangle_1630" />
      </g>
      <path
        fill="currentColor"
        id="link-solid"
        d="M5341.7,978.4c1.7-1.7,1.7-4.4,0-6.1c-1.5-1.5-3.8-1.7-5.5-0.5l0,0c-0.4,0.3-0.5,0.9-0.2,1.3
  c0.3,0.4,0.9,0.5,1.3,0.2l0,0c1.1-0.8,2.6-0.5,3.3,0.5c0.7,1,0.6,2.3-0.3,3.1l-3.3,3.3c-0.9,1-2.4,1-3.4,0.1
  c-0.9-0.8-1-2.2-0.3-3.2l0,0c0.3-0.4,0.2-1-0.2-1.3c-0.4-0.3-1-0.2-1.3,0.2l0,0.1c-1.4,1.9-1,4.6,0.9,6c1.7,1.3,4.1,1.1,5.6-0.5
  L5341.7,978.4z M5326.3,977.7c-1.7,1.7-1.7,4.4,0,6.1c1.5,1.5,3.8,1.7,5.5,0.5l0,0c0.4-0.3,0.5-0.9,0.2-1.3
  c-0.3-0.4-0.9-0.5-1.3-0.2l0,0c-1.1,0.8-2.6,0.5-3.4-0.5c-0.7-1-0.6-2.3,0.3-3.1l3.3-3.3c0.9-1,2.4-1,3.4-0.1
  c0.9,0.8,1,2.2,0.3,3.2l0,0c-0.3,0.4-0.2,1,0.2,1.3s1,0.2,1.3-0.2l0,0c1.4-1.9,0.9-4.6-1-6c-1.7-1.2-4.1-1-5.6,0.5L5326.3,977.7z"
      />
    </g>
  </svg>
);

const Clipboard = () => (
  <span className="material-icons-round">content_copy</span>
);

type CopyButtonProps = {
  text?: string;
  icon?: "link" | "clipboard";
};

const CopyButton = ({ text, icon = "link" }: CopyButtonProps) => {
  const [copied, setCopied] = useState(false);

  const copyLink = () => {
    if (copied) {
      return;
    }
    navigator.clipboard.writeText(text || window.location.href);
    setCopied(true);

    // rehide the checkmark after 2 seconds
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const determineIcon = () => {
    if (copied) {
      return <CheckMark />;
    }

    return icon === "clipboard" ? <Clipboard /> : <CopyLink />;
  };

  return (
    <>
      <button
        className={`social-share-button copy-link-button ${copied ? "copied" : ""}`}
        type="button"
        onClick={copyLink}
        aria-label="Copy URL to clipboard"
        data-tooltip-id="copied-tooltip"
      >
        {determineIcon()}
      </button>

      <div className="tooltip-container">
        <Tooltip
          id="copied-tooltip"
          content="Copied!"
          noArrow
          isOpen={copied}
          className="tooltip copied-tooltip"
        />
      </div>

      <style jsx>
        {`
          .copy-link-button.copied {
            background: transparent;
            cursor: default;
          }
          .copy-link-button :global(svg) {
            border-radius: 50%;
            height: 28px;
            width: 28px;
            flex: 1 0 auto;
          }
          .copy-link-button.copied :global(svg) {
            color: ${theme.colors.kellyGreen};
          }
          .copy-link-button.copied:hover {
            background: transparent;
          }
          .copy-link-button.copied:hover :global(svg) {
            color: ${theme.colors.kellyGreen};
          }
        `}
      </style>
    </>
  );
};

export default CopyButton;
