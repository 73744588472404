/* eslint-disable react/destructuring-assignment */
import { CDNUrlForImage } from "@adapters/ImageCDN";
import { Config } from "@configured/EnvironmentConfig";
import Image from "next/image";
import { useState } from "react";

export interface ImageComponent {
  (props: React.ImgHTMLAttributes<HTMLImageElement>): JSX.Element;
}

function importedImage(path: string) {
  const p = path?.replace("/images/", "");
  // eslint-disable-next-line import/no-dynamic-require, global-require
  return require(`images/${p}`);
}

function isBlurable(path: string) {
  return /(png|jpg|webp)$/i.test(path);
}

const OptimizedImage: ImageComponent = (props) => {
  if (props.src?.startsWith("http")) {
    // eslint-disable-next-line jsx-a11y/alt-text
    return <img {...props} />;
  }

  const image = importedImage(props.src || "");
  const placeholder = isBlurable(props.src || "") ? "blur" : "empty";
  const [fallback, setFallback] = useState(false);
  return (
    <>
      <Image
        alt={props.alt as string} // todo: not safe, but next/image throws nice errors for unset alt tags.
        {...props}
        style={{ maxWidth: "100%", height: "auto" }}
        width={undefined}
        height={undefined}
        onError={(e) => {
          const img = e.target as HTMLImageElement;
          const err = new Error(
            `Error Loading Image: ${img.alt} ${img.currentSrc}`,
          );
          setFallback(true);
          throw err;
        }}
        src={image}
        loader={Config.imageCdnUrl ? CDNUrlForImage : undefined}
        unoptimized={fallback}
        placeholder={placeholder}
      />
    </>
  );
};

export function OptimizedImageURL(imagePath: string, pxWidth: number) {
  // if its from another server, we need to use the full path
  if (imagePath.startsWith("http")) {
    return imagePath;
  }

  const hashedPath = importedImage(imagePath)?.default?.src;

  // if its from our server, we need to use the CDN
  return Config.imageCdnUrl
    ? CDNUrlForImage({ src: hashedPath, width: pxWidth })
    : hashedPath || `error importing ${imagePath} to ${hashedPath}`;
}

const DEFAULT = OptimizedImage;
export default DEFAULT;
export { OptimizedImage };
