import { ReactNode, useContext, useEffect } from "react";
import { useRouter } from "next/router";
import classNames from "classnames";
import Header from "@components/layout/Header";
import MobileTopNavMenu from "@components/layout/MobileTopNavMenu";
import { LayoutContext } from "@components/layout/Layout";
import { Main } from "@components/layout/Main";
import Footer from "@components/layout/Footer";
import theme from "@config/theme";

type SiteChromeProps = {
  children: ReactNode;
  selectedPage?: string;
  noPadding?: boolean;
};

const defaultProps = {
  selectedPage: "",
  noPadding: false,
};

const SiteChrome = ({
  children,
  selectedPage = defaultProps.selectedPage,
  noPadding = defaultProps.noPadding,
}: SiteChromeProps) => {
  const {
    isMobileTopNavMenuOpen,
    setIsMobileTopNavMenuOpen,
    setREPLCollapsed,
  } = useContext(LayoutContext);

  const routeSlugs = useRouter().query.slugs;

  useEffect(() => {
    // collapse mobile nav menu new page is selected and autohide REPL on all of these types of non-docs pages
    setIsMobileTopNavMenuOpen(false);
    setREPLCollapsed(true);
  }, [routeSlugs]);

  return (
    <>
      <div className={classNames("site-chrome-container", { noPadding })}>
        <Header selectedPage={selectedPage} />
        <MobileTopNavMenu isOpen={isMobileTopNavMenuOpen} />
        <Main>
          <div className="site-chrome-page-wrapper">{children}</div>
        </Main>
        <Footer />
      </div>
      <style jsx>{`
        .site-chrome-container {
          width: 100vw;
        }

        .site-chrome-container + :global(.repl-positioner:not(.fullsize)) {
          max-width: calc(99vw - 50px);
        }

        .site-chrome-page-wrapper {
          padding: 0 0 3.5rem 0;
          margin: auto;
        }

        .site-chrome-page-wrapper h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin-top: 2.5rem;
        }

        .site-chrome-page-wrapper h2 {
          font-size: ${theme.fonts.size.px24};
        }

        .site-chrome-page-wrapper h4 {
          font-size: ${theme.fonts.size.px18};
        }

        .site-chrome-container :global(.mobile-top-nav-menu-wrapper) {
          display: none;
        }

        .site-chrome-container.noPadding :global(.page-main) {
          padding-left: 0;
          padding-right: 0;
        }

        .site-chrome-container.noPadding :global(.main-container) {
          width: auto;
          transform: none !important;
        }

        @media screen and (max-width: ${theme.breakpoints
            .condenseTopNavBar}px) {
          .site-chrome-container :global(.mobileTopNavMenuIconContainer) {
            display: flex;
          }

          // show site chrome mobile top nav menu when on site chrome pages
          .site-chrome-container :global(.mobile-top-nav-menu-wrapper.isOpen) {
            display: block;
          }

          // hide docs mobile nav menu when on site chrome pages
          .site-chrome-container :global(.menuIconContainer) {
            display: none;
          }
        }

        @media screen and (max-width: ${theme.breakpoints.noReplMobile -
          10}px) {
          .site-chrome-container {
            width: auto;
          }
        }
      `}</style>
    </>
  );
};

SiteChrome.defaultProps = defaultProps;

export default SiteChrome;
