import { PropsWithChildren, useContext } from "react";
import theme from "@config/theme";
import { LayoutContext } from "@components/layout/Layout";
import { BluesErrorBoundary } from "@components/BluesErrorBoundary";

export const Main = ({ children }: PropsWithChildren) => {
  const { hideREPL, isAnnouncementBannerShowing } = useContext(LayoutContext);
  return (
    <main className="page-main">
      <div
        className={`main-container ${hideREPL ? "hide-repl" : ""} ${
          isAnnouncementBannerShowing ? "visible-banner" : ""
        } `}
      >
        <BluesErrorBoundary boundaryName="Main">{children}</BluesErrorBoundary>
      </div>

      <style jsx>{`
        .page-main {
          padding: 1rem;
          border-bottom-left-radius: 3px;
          container-type: inline-size;
          grid-area: main;
          z-index: ${theme.zIndex.main};
        }

        // largest window width move content to right on repl collapsed
        @media screen and (min-width: ${theme.breakpoints.lg + 1}px) {
          :global(.page-layout.replExpanded .main-container) {
            animation: none;
            width: 59%;
          }

          :global(
              .page-layout.replCollapsed .main-container,
              .page-layout.replExpanded .main-container.hide-repl
            ) {
            transform: translateX(calc(50% - calc(80% / 2))) !important;
            width: 80%;
          }
        }
      `}</style>
    </main>
  );
};

const forceNamedExport = {};
export default forceNamedExport;
