interface DocTableProps {
  children: React.ReactNode;
  compact?: boolean;
  verticalCenterCells?: boolean;
}

const defaultProps = {
  compact: false,
  verticalCenterCells: false,
};

function DocTable({ children, compact, verticalCenterCells }: DocTableProps) {
  const compactClass = compact ? "compact" : "";
  const verticalCenterClass = verticalCenterCells
    ? "vertical-center-cells"
    : "";

  return (
    <>
      <div className="table-container scroll-shadows">
        <table className={`${compactClass} ${verticalCenterClass}`}>
          {children}
        </table>
      </div>
      <style jsx>{`
        .table-container {
          max-width: 100%;
        }
        .scroll-shadows {
          // The cover is larger so the shadow will fade gradually
          --cover-size: 80px;
          --shadow-size: 28px;
          --shadow-color: rgba(0, 0, 0, 0.15);

          overflow: auto;

          background:
            // Shadow Covers
            linear-gradient(to right, white 30%, transparent) center left,
            linear-gradient(to left, white 30%, transparent) center right,
            linear-gradient(white 30%, transparent) center top,
            linear-gradient(transparent, white 30%) center bottom,
            // Shadows
            linear-gradient(to right, var(--shadow-color), transparent) center
              left,
            linear-gradient(to left, var(--shadow-color), transparent) center
              right,
            linear-gradient(var(--shadow-color), transparent) center top,
            linear-gradient(transparent, var(--shadow-color)) center bottom;

          background-repeat: no-repeat;
          background-size:
            var(--cover-size) 100%,
            var(--cover-size) 100%,
            100% var(--cover-size),
            100% var(--cover-size),
            var(--shadow-size) 100%,
            var(--shadow-size) 100%,
            100% var(--shadow-size),
            100% var(--shadow-size);
          background-attachment: local, local, local, local, scroll, scroll,
            scroll, scroll;

          // Hide scroll bars
          -ms-overflow-style: none; // IE and Edge
          scrollbar-width: none; // Firefox
          &::-webkit-scrollbar {
            display: none; // Chrome, Safari, and Opera
          }
        }
      `}</style>
    </>
  );
}

DocTable.defaultProps = defaultProps;

export default DocTable;
