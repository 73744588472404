import { ReactElement } from "react";
import {
  isCellularSKU,
  isCellular_GL_SKU,
  isCellular_NA_SKU,
  isCellular_V1_SKU,
  isCellular_V2_SKU,
  isCellular_V3_SKU,
  isCellular_V4_SKU,
  isCellular_XP_SKU,
  isNBGLFamilySKU,
  isNBNAFamilySKU,
  isNarrowBandSKU,
  isSkuHasWiFi,
  isWBEXFamilySKU,
  isWBNAFamilySKU,
  isWiFiOnlySKU,
  isWideBandSKU,
  is_V1_SKU,
  isMBGLFamilySKU,
  isMBNAFamilySKU,
  isMidBandSKU,
} from "../services/NotecardSKUs";

type Props = {
  children: ReactElement;
  notecard: string;
};

export const IfCellularV1SKU = ({ children, notecard }: Props) =>
  isCellular_V1_SKU(notecard) ? children : null;

export const IfCellularV2SKU = ({ children, notecard }: Props) =>
  isCellular_V2_SKU(notecard) ? children : null;

export const IfCellularV3SKU = ({ children, notecard }: Props) =>
  isCellular_V3_SKU(notecard) ? children : null;

export const IfCellularV4SKU = ({ children, notecard }: Props) =>
  isCellular_V4_SKU(notecard) ? children : null;

export const IfCellularXPSKU = ({ children, notecard }: Props) =>
  isCellular_XP_SKU(notecard) ? children : null;

export const IfV1SKU = ({ children, notecard }: Props) =>
  is_V1_SKU(notecard) ? children : null;

export const IfCellularNASKU = ({ children, notecard }: Props) =>
  isCellular_NA_SKU(notecard) ? children : null;

export const IfCellularGLSKU = ({ children, notecard }: Props) =>
  isCellular_GL_SKU(notecard) ? children : null;

export const IfWideBandSKU = ({ children, notecard }: Props) =>
  isWideBandSKU(notecard) ? children : null;

export const IfNarrowBandSKU = ({ children, notecard }: Props) =>
  isNarrowBandSKU(notecard) ? children : null;

export const IfWBEXFamilySKU = ({ children, notecard }: Props) =>
  isWBEXFamilySKU(notecard) ? children : null;

export const IfWBNAFamilySKU = ({ children, notecard }: Props) =>
  isWBNAFamilySKU(notecard) ? children : null;

export const IfNBGLFamilySKU = ({ children, notecard }: Props) =>
  isNBGLFamilySKU(notecard) ? children : null;

export const IfNBNAFamilySKU = ({ children, notecard }: Props) =>
  isNBNAFamilySKU(notecard) ? children : null;

export const IfCellularSKU = ({ children, notecard }: Props) =>
  isCellularSKU(notecard) ? children : null;

export const IfSkuHasWifi = ({ children, notecard }: Props) =>
  isSkuHasWiFi(notecard) ? children : null;

export const IfWiFiOnlySKU = ({ children, notecard }: Props) =>
  isWiFiOnlySKU(notecard) ? children : null;

export const IfMBGLFamilySKU = ({ children, notecard }: Props) =>
  isMBGLFamilySKU(notecard) ? children : null;

export const IfMBNAFamilySKU = ({ children, notecard }: Props) =>
  isMBNAFamilySKU(notecard) ? children : null;

export const IfMidBandSKU = ({ children, notecard }: Props) =>
  isMidBandSKU(notecard) ? children : null;

// We use one parent SKU, note-lora, that handles the datasheet for both
// note-lwus and note-lweu
export const IfLoRaSKU = ({ children, notecard }: Props) =>
  notecard.includes("note-lora") ? children : null;
export const IfNotLoRaSKU = ({ children, notecard }: Props) =>
  !notecard.includes("note-lora") ? children : null;

type ShowIfVersionMatchProps = {
  children: ReactElement;
  notecard: string;
  skuTypes: string[];
};

export const NotecardSKUCheck = ({
  children,
  notecard,
  skuTypes,
}: ShowIfVersionMatchProps) => {
  let returnValue = null;
  skuTypes.forEach((sku) => {
    if (sku === "cell" && isCellularSKU(notecard)) {
      returnValue = children;
    }
    if (sku === "cell1" && isCellular_V1_SKU(notecard)) {
      returnValue = children;
    }
    if (sku === "cell2" && isCellular_V2_SKU(notecard)) {
      returnValue = children;
    }
    if (sku === "cell3" && isCellular_V3_SKU(notecard)) {
      returnValue = children;
    }
    if (sku === "cell4" && isCellular_V4_SKU(notecard)) {
      returnValue = children;
    }
    if (sku === "lora" && notecard.toLowerCase() === "note-lora") {
      returnValue = children;
    }
    if (sku === "wifi" && isWiFiOnlySKU(notecard)) {
      returnValue = children;
    }
    if (sku === "wifi1" && notecard.toLowerCase() === "note-wifi") {
      returnValue = children;
    }
    if (sku === "wifi2" && notecard.toLowerCase() === "note-esp") {
      returnValue = children;
    }
  });
  return returnValue;
};
