import zipObject from "lodash/zipObject";

import { DocConfig } from "@config/nav";
import { pad, urlSegments } from "@scripts/utils";
import { OptionObjProps } from "@content/en/Options";

export const fullyConfigured = (selected: Selections): boolean =>
  !Object.values(selected).includes(null);

export const selectionsFromSlugs = (keys: string[], values: string[]): Combo =>
  zipObject(keys, pad(values, keys.length, null));

export function pathsToOptionCombinations(
  paths: string[],
  slugs: string[],
): Combo[] {
  return paths.map((path) => selectionsFromSlugs(slugs, urlSegments(path)));
}

export const constantUrlSegments = (url: string) =>
  // We subtract 1 here because the first segment is the *galaxy*, which I
  // *think* is always present?
  urlSegments(url).length - 1;

export const selectionsFromUrl = (
  slugs: string[],
  config: DocConfig,
): Selections =>
  selectionsFromSlugs(
    config.slugs,
    slugs.slice(constantUrlSegments(config.pathBase)),
  );
type Dimension = string;
type Option = string;
type Combo = Record<Dimension, Option>;
type Selection = string | null;
export type Selections = Record<Dimension, Selection>;

function isCompatible(opt: Option, selection: string | null) {
  return selection === null || opt === selection;
}

function markIncompatible(option: string) {
  return `~${option}`;
}

function isComboOkWithSelections(combo: Combo, selections: Selections) {
  const compatibilityList = Object.entries(combo).map(([dim, opt]) =>
    isCompatible(opt, selections[dim]),
  );
  return !compatibilityList.includes(false);
}

function removeExtraIncompatibles(result: OptionObjProps) {
  Object.keys(result).forEach((dimension) => {
    result[dimension].forEach((option) => {
      // eslint-disable-next-line no-param-reassign
      result[dimension] = result[dimension].filter(
        (o) => o !== markIncompatible(option),
      );
    });
  });
  return result;
}

export const currentOptions = (
  combos: Combo[],
  selections: Selections,
  dimensions: Dimension[],
): OptionObjProps => {
  const acc: Record<Dimension, Set<Option>> = {};

  dimensions.forEach((dim) => {
    acc[dim] = new Set();
    combos.forEach((combo) => {
      let toAdd: Option;
      if (
        isComboOkWithSelections(combo, { ...selections, ...{ [dim]: null } })
      ) {
        toAdd = combo[dim];
      } else {
        toAdd = markIncompatible(combo[dim]);
      }
      acc[dim].add(toAdd);
    });
  });
  const result: OptionObjProps = {};
  Object.entries(acc).forEach(([dim, set]) => {
    const values = Array.from(set.values());
    // Don't sort single dimension option combinations
    result[dim] = Object.keys(acc).length > 1 ? values.sort() : values;
  });
  removeExtraIncompatibles(result);
  return result;
};

export function mostRecentlyVisitedPath(
  pathCombinations: string[][],
  mostRecentlySelected: (options: string[]) => string | undefined,
): string[] {
  const optionsForNextChoice = pathCombinations.map((c) => c[0]);
  const nextOption = mostRecentlySelected(optionsForNextChoice);
  if (nextOption === undefined) {
    return [];
  }
  const remainingOptions = pathCombinations
    .filter((c) => c[0] === nextOption)
    .map((c) => c.slice(1));
  return [
    nextOption,
    ...mostRecentlyVisitedPath(remainingOptions, mostRecentlySelected),
  ];
}

export const slugsFromSettings = (
  allPaths: string[],
  mostRecentlySelected: (titles: string[]) => string | undefined,
): string[] =>
  mostRecentlyVisitedPath(
    allPaths.map((p) => urlSegments(p)),
    mostRecentlySelected,
  );
