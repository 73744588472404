import * as React from "react";
import theme from "@config/theme";
import { ImgSrc } from "@config/image";

type MoreInformationContent = {
  children: React.ReactNode;
};

const MoreInformation = ({ children }: MoreInformationContent) => (
  <>
    <div className="floatfollower" />
    <div className="moreInformation">
      <header>More information:</header>
      {children}
    </div>
    <style jsx>{`
      // Force a break clear across the document
      .floatfollower {
        box-sizing: border-box;
        clear: both;
        width: 100%;
      }
      .moreInformation {
        font-size: ${theme.fonts.size.px18};
        margin-top: 40px;
      }
      .moreInformation :global(p) {
        margin: 0;
      }
      .moreInformation :global(ul) {
        font-weight: 500;
        list-style-type: none;
        padding: 0;
      }
      .moreInformation :global(li) {
        margin: 12px 12px 12px 0;
        padding-left: 26px;
        background-image: url(${ImgSrc(
          "/images/icons/arrow-right-14px-mutedBlue.svg"
        )});
        background-position: left 0px top 6px; /* 6px = (26px-14px)/2 */
        line-height: 26px;
        background-repeat: no-repeat;
      }
      .moreInformation :global(a) {
        color: ${theme.colors.gray1};
        text-decoration-line: underline;
      }
      header {
        color: ${theme.colors.gray1};
        font-weight: bold;
        padding-top: 8px;
        margin-bottom: 12px;
      }
    `}</style>
  </>
);

export default MoreInformation;
