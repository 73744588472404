import React, { useContext } from "react";
import { AuthContext } from "@adapters/AuthContextProvider";
import { AnalyticsGateway } from "@components/analytics/AnalyticsGateway";

type SignIn = {
  children: React.ReactNode;
};

// eslint-disable-next-line import/prefer-default-export
export const SignInToNotehub = ({ children }: SignIn) => {
  const { userInfo, authActions } = useContext(AuthContext);

  const handleSignUpRedirect = () => {
    authActions.signUp();
    AnalyticsGateway().trackEvent("Sign Up Clicked", {
      location: "quickstart sign up document widget",
    });
  };

  const handleSignInRedirect = () => {
    authActions.signIn();
    AnalyticsGateway().trackEvent("Sign In Clicked", {
      location: "quickstart sign in document widget",
    });
  };

  return (
    <div>
      {!userInfo.signedIn ? (
        <>
          Sign in or sign up for Notehub using the links below.
          <div className="signInToNotehub">
            <h4>Create a Notehub Account</h4>
            <div className="buttons">
              <button
                className="btn btnSecondary"
                onClick={handleSignUpRedirect}
                type="button"
              >
                Sign up
              </button>
            </div>
            <style jsx>{`
              .signInToNotehub {
                margin-top: 1rem;
                max-width: 550px;
                padding: 30px;
                border: 2px solid #eceff1;
                border-radius: 5px;
                background: white;
                -webkit-box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
                box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
              }

              .signInToNotehub h4 {
                color: #1b3a52;
                font-size: 1.25rem;
                font-weight: 600;
                line-height: 28px;
                margin: 0 0 1rem 0;
                min-height: 28px;
              }

              .buttons {
                text-align: center;
                margin-bottom: 1rem;
              }

              .signInToNotehubText {
                text-align: center;
              }
            `}</style>
            <div className="signInToNotehubText">
              Already have an account?&nbsp;
              <a onClick={handleSignInRedirect}>Sign in</a>
            </div>
          </div>
        </>
      ) : (
        children
      )}
    </div>
  );
};
