/* eslint-disable no-nested-ternary */
import theme from "@config/theme";
import { OptimizedImageURL } from "@components/OptimizedImage";
import { AspectRatioBox } from "./AspectRatioBox";

interface Props {
  children?: React.ReactNode;
  heightPx?: number;
  widthPx?: number;
  youtubeEmbedUrl?: string;
  src?: string;
  poster?: string;
}

const urlForNoCookies = (url: string) =>
  url
    .replace("youtube.com", "youtube-nocookie.com")
    .replace("www.youtube.com", "www.youtube-nocookie.com");

const VideoBox = ({
  children,
  heightPx,
  widthPx,
  youtubeEmbedUrl,
  src,
  poster,
}: Props) => (
  <>
    <AspectRatioBox
      heightPx={heightPx || 315}
      widthPx={widthPx || 560}
      className="video-box"
    >
      {youtubeEmbedUrl ? (
        <iframe
          src={urlForNoCookies(youtubeEmbedUrl)}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      ) : src ? (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video
          controls
          src={src}
          poster={poster && OptimizedImageURL(poster, widthPx || 560)}
          preload="metadata" // just fetch video length etc.
        />
      ) : (
        children
      )}
    </AspectRatioBox>
    <style jsx>{`
      :global(.video-box) {
        margin: 1rem 0;
        border-radius: 12px;
        /* Limit the size of the responsive video on large screens */
        max-height: 300px;
        /* To maintain the aspect ratio of the video */
        width: 100%;
      }

      video,
      iframe {
        width: 100%;
        height: 100%;
      }

      iframe {
        border: none;
      }

      @media only screen and (max-width: ${theme.breakpoints.md}px) {
        :global(.video-box) {
          max-height: inherit;
          margin: 2rem 0 1rem 0;
        }
      }
    `}</style>
  </>
);

export { VideoBox };
