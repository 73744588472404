import theme from "@config/theme";
import DropDownButton from "@components/DropDownButton";
import { Options } from "@content/en/Options";
import { formatOptionVal } from "@scripts/utils";
import { Selections } from "@scripts/SlugPickerUtils";
import { MenuItem } from "@components/layout/Menu";

type MultiSelectProps = {
  options: Record<string, string[]>;
  selected: Selections;
  text?: string;
  onChange: (key: string, value: string, isGrayedOut?: boolean) => void;
};

const menuItemFromTitle = (title: string): MenuItem => ({
  href: "",
  items: [],
  level: 1,
  selected: false,
  title,
  type: "link",
});

const DropDownHeader = ({
  title,
  selected,
}: {
  title: string;
  selected: Selections;
}) => {
  const selectedOption = selected[title];
  if (selectedOption) {
    return (
      <>
        {formatOptionVal(Options, title, selectedOption)}
        <span className="arrow" />
      </>
    );
  }
  return (
    <>
      <b>[choose a {title}]</b>
      <span className="arrow" />
    </>
  );
};

const isIncompatible = (label: string) => label.startsWith("~");

const MultiSelect = ({
  onChange,
  options,
  selected,
  text,
}: MultiSelectProps) => (
  <div className="choiceBarContainer">
    {text}
    {Object.keys(options).map((selection, index) => (
      <div className="inline" key={selection}>
        <DropDownButton
          btnClickableElement={
            <DropDownHeader title={selection} selected={selected} />
          }
          id={selection}
          items={
            options[selection]?.map((label) => ({
              ...menuItemFromTitle(formatOptionVal(Options, selection, label)),
              ...{ isGrayedOut: isIncompatible(label) },
            })) || []
          }
          onChange={(item) => {
            onChange(selection, item.title, item.isGrayedOut);
          }}
        />
        {index < Object.keys(options).length - 1 && (
          <span className="spanAnd">and</span>
        )}
      </div>
    ))}
    <style jsx>{`
      .inline {
        display: inline-flex; // html validation
        align-items: center;
      }
      .choiceBarContainer {
        // to sit below the REPL collapse/expand toggle widget
        margin-top: 56px;
        width: 100%;
        background-color: ${theme.colors.lightSnowBlue};
        padding: 1rem;
        font-size: ${theme.fonts.size.px16};
        font-weight: 500;
        border-radius: 4px;
      }

      .choiceBarContainer :global(.dropdown) {
        margin: 0 0.5rem;
      }

      .choiceBarContainer :global(.arrow) {
        margin-left: 0.25rem;
      }

      .choiceBarContainer :global(.dropbtn) {
        background-color: transparent;
        border: none;
        cursor: pointer;
        border-bottom: 1px dotted ${theme.colors.black};
        font-size: ${theme.fonts.size.px16};
        color: ${theme.colors.black};
        font-weight: 500;
        padding: 0.2rem 0.1rem;
        position: relative;
        display: flex;
        align-items: center;
      }

      .choiceBarContainer :global(.dropdown-content) {
        transform: translate(0, 0);
      }

      pre {
        overflow: scroll;
      }
    `}</style>
  </div>
);
MultiSelect.defaultProps = { text: "Get started with:" };

export { MultiSelect };
