import theme from "@config/theme";
import { PropsWithChildren } from "react";

const VideoCallout = ({ children }: PropsWithChildren) => (
  <>
    <div className="video-callout">
      <h4>Watch a video of this tutorial</h4>

      {/* Make the video just large enough to get a high-quality YouTube thumbnail on medium and large screens */}
      {/* https://stackoverflow.com/questions/57080685/youtube-embed-player-thumbnails-are-pixelated-for-small-player-but-look-fine-f */}
      {children}
    </div>

    <style jsx>{`
      .video-callout {
        border: 1px solid ${theme.colors.lightGray};
        border-radius: 5px;
        margin: 0 0 1rem 1rem;
        float: right;
        padding: 1rem;
        width: 360px;
        position: relative;
      }
      h4 {
        margin: 0 0 0.5rem 0;
        font-size: 1rem;
      }
      @media screen and (max-width: ${theme.breakpoints.md}px) {
        .video-callout {
          width: 250px;
          margin-bottom: 1rem;
        }
      }
    `}</style>
  </>
);

export default VideoCallout;
