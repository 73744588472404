const GitHubLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 23"
    width="24"
    height="24"
  >
    <g id="Layer_2-2">
      <g id="github-mark">
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="m11.512,0C5.102.053-.052,5.38,0,11.899c.04,5.019,3.197,9.463,7.87,11.079.575.117.781-.253.781-.566,0-.273-.019-1.21-.019-2.187-3.202.703-3.869-1.405-3.869-1.405-.203-.713-.657-1.325-1.277-1.718-1.047-.722.077-.722.077-.722.744.102,1.398.549,1.772,1.21.64,1.205,2.119,1.654,3.304,1.003.017-.009.034-.019.05-.028.048-.598.305-1.159.725-1.581-2.554-.273-5.24-1.288-5.24-5.818-.013-1.168.41-2.297,1.183-3.161-.35-1.019-.309-2.134.114-3.124,0,0,.972-.313,3.163,1.21,1.883-.52,3.868-.52,5.75,0,2.192-1.523,3.163-1.21,3.163-1.21.423.99.464,2.105.114,3.124.781.859,1.204,1.993,1.182,3.163,0,4.529-2.683,5.525-5.26,5.818.56.579.845,1.377.781,2.186,0,1.581-.019,2.851-.019,3.241,0,.313.21.682.781.566,6.069-2.098,9.317-8.802,7.254-14.974C20.794,3.261,16.438.053,11.512,0Z"
        />
      </g>
    </g>
  </svg>
);

export default GitHubLogo;
