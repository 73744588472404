import { cloneDeep } from "lodash";
import CommunityProject from "@components/community/CommunityProject";

// HacksterProject is the shape of the data coming out of Hackster's API.
export interface HacksterProject {
  collection_featured: boolean;
  collection_published_at: string; // e.g. '2023-03-18T20:51:59Z'
  content_type:
    | "tutorial"
    | "wip"
    | "getting_started"
    | "protip"
    | "showcase"
    | "teardown"
    | string;
  cover_image_url: string; // e.g. https://hackster.imgix.net/uploads/attachments/1572974/ezgif_com-optimize_(1)_Klp1QUp1nB.gif?auto=format%2Ccompress&gifq=35&w=400&h=300&fit=min
  difficulty: "beginner" | "intermediate" | "advanced" | "hardcore";
  featured: boolean;
  featured_at: null | string; // e.g. '2023-01-24T13:58:59Z'
  hid: string; // e.g. 8346aa
  name: string; // e.g. Remote Firmware Updates that Can't Brick Your Host MCU
  one_liner: string; // e.g. This project shows how to use the Notecard and Outboard Firmware Update to remotely perform DFU without the risk of bricking your host.
  published_at: string; // e.g. '2023-03-21T19:30:37Z'
  stats: {
    respects: number;
    views: number;
  };
  team: {
    members: {
      url: string; // e.g.  https://www.hackster.io/rob-lauer
      name: string; // e.g.  Rob Lauer
    }[];
    name: null | string;
  };
  url: string; // e.g. https://www.hackster.io/rob-lauer/remote-firmware-updates-that-can-t-brick-your-host-mcu-8346aa
}

function formatAuthor(p: HacksterProject) {
  return p.team.name || p.team.members.map((m) => m.name).join(" & ");
}

export function transformHacksterProject(
  p: HacksterProject,
  overrides?: Partial<CommunityProject>
): CommunityProject {
  const ourDifficultyFromHacksterDifficulty = {
    beginner: "Beginner" as "Beginner",
    intermediate: "Intermediate" as "Intermediate",
    advanced: "Advanced" as "Advanced",
    hardcore: "Expert" as "Expert",
  };
  const project = {
    id: p.hid,
    author: formatAuthor(p),
    coverImageUrl: p.cover_image_url,
    description: p.one_liner,
    difficulty: ourDifficultyFromHacksterDifficulty[p.difficulty],
    featured: p.featured,
    publicationDate: new Date(p.published_at),
    respects: p.stats.respects,
    title: p.name,
    url: p.url,
    views: p.stats.views,
  };
  return {
    ...project,
    ...overrides,
  };
}

export function categorizeHacksterProjects(projects: CommunityProject[]) {
  const projectListCopy = cloneDeep(projects);

  // Give the top three projects a "Trending" category.
  if (projectListCopy.length > 2) {
    projectListCopy[0].category = "Trending";
    projectListCopy[1].category = "Trending";
    projectListCopy[2].category = "Trending";
  }

  // Give all projects with no categories an "Other Projects" category.
  projectListCopy
    .filter((p) => !p.category)
    .forEach((p) => {
      // eslint-disable-next-line no-param-reassign
      p.category = "Other Projects";
    });

  const finalProjectList: { [key: string]: CommunityProject[] } = {};
  [
    "Trending",
    "GPS/GNSS Tracking",
    "Machine Learning",
    "Remote Monitoring",
    "Fun!",
    "Home Automation",
    "Other Projects",
  ].forEach((category) => {
    finalProjectList[category] = projectListCopy.filter(
      (p) => p.category === category
    );
  });

  return finalProjectList;
}
