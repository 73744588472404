import { useContext } from "react";
import { AuthContext } from "@adapters/AuthContextProvider";
import { ImgSrc } from "@config/image";
import theme from "@config/theme";
import { AnalyticsGateway } from "@components/analytics/AnalyticsGateway";
import { Tab } from "@components/menu/Tab";
import { MenuItem } from "@components/layout/Menu";
import classNames from "classnames";

const UserMenu = () => {
  const { authActions, userInfo } = useContext(AuthContext);

  const handleSignIn = () => {
    AnalyticsGateway().trackEvent("Sign In Clicked");
    authActions.signIn();
  };

  const handleViewProjects = () => {
    AnalyticsGateway().trackEvent("View Projects Clicked");
    authActions.viewProjects();
  };

  const handleSignOut = () => {
    AnalyticsGateway().trackEvent("Sign Out Clicked");
    authActions.signOut();
  };

  const handleAccountSettings = () => {
    AnalyticsGateway().trackEvent("Account Settings Clicked");
    authActions.openAccountSettings();
  };

  // desktop log in
  const desktopMenuSignIn: MenuItem = {
    title: "Sign In",
    type: "link",
    onClick: handleSignIn,
    items: [],
    level: 1,
    selected: false,
  };

  // mobile log in dropdown
  const mobileMenuSignIn: MenuItem = {
    title: "Sign In",
    icon: ImgSrc("/images/icons/account_circle-white.svg"),
    type: "section",
    level: 1,
    selected: false,
    items: [
      {
        title: "Sign In",
        type: "link",
        onClick: handleSignIn,
        items: [],
        level: 2,
        selected: false,
      },
    ],
  };

  // signed in user dropdown
  const userSignedInMenu: MenuItem = {
    title: userInfo.name,
    icon: ImgSrc("/images/icons/account_circle-white.svg"),
    type: "section",
    level: 1,
    selected: false,
    items: [
      {
        title: "Account Settings",
        type: "link",
        onClick: handleAccountSettings,
        items: [],
        level: 2,
        selected: false,
      },
      {
        title: "View Projects",
        type: "link",
        onClick: handleViewProjects,
        items: [],
        level: 2,
        selected: false,
      },
      {
        title: "Sign Out",
        type: "link",
        onClick: handleSignOut,
        items: [],
        level: 2,
        selected: false,
      },
    ],
  };

  return (
    <>
      <div
        className={classNames("userMenu", {
          isSignedOut: !userInfo.signedIn,
        })}
      >
        <div className="userMenuContainer">
          {userInfo.signedIn ? (
            <Tab {...userSignedInMenu} />
          ) : (
            <>
              <div className="userMenuMobileSignIn">
                <Tab {...mobileMenuSignIn} />
              </div>
              <div className="userMenuDesktopSignIn">
                <Tab {...desktopMenuSignIn} />
              </div>
            </>
          )}
        </div>
      </div>

      <style jsx>{`
        .userMenu {
          height: 100%;
          padding: 0 1rem;
        }

        .userMenu.isSignedOut
          .userMenuDesktopSignIn
          :global(.tabContainer .tabLink span) {
          color: ${theme.colors.saffronYellow};
          text-decoration: underline;
          white-space: nowrap;
        }

        .userMenu.isSignedOut .userMenuMobileSignIn {
          display: none;
        }

        // truncate title text with ellipsis
        .userMenu :global(.tabTitle) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100px;
        }

        .userMenu :global(.tabContainer .dropdown-content) {
          margin: -0.5rem 0 0 2.5rem;
        }

        // move drop down left for mobile
        @media screen and (max-width: ${theme.breakpoints.sm}px) {
          .userMenu.isSignedOut
            .userMenuDesktopSignIn
            :global(.tabContainer .tabLink span) {
            display: none;
          }

          .userMenu :global(.tabContainer .dropdown-content) {
            margin: -0.5rem 0 0 -2rem;
          }

          .userMenu.isSignedOut .userMenuMobileSignIn {
            display: block;
          }
        }
      `}</style>
    </>
  );
};

export { UserMenu };
