import { DetailedHTMLProps, EmbedHTMLAttributes } from "react";

// todo when the KiCanvas API of the future has an npm library version, remove the source code in public/kicanvas/ and replace with lib instead

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "kicanvas-embed": DetailedHTMLProps<KiCanvasEmbedAttributes, HTMLElement>;
    }
  }
}

interface KiCanvasEmbedAttributes extends EmbedHTMLAttributes<HTMLElement> {
  controls?: string;
}

type KiCanvasProps = {
  schematic_path: string;
  controls?: string;
};

const defaultProps = {
  controls: "basic",
};

const KiCanvas = ({ schematic_path, controls }: KiCanvasProps) => (
  <kicanvas-embed src={schematic_path} controls={controls} />
);

KiCanvas.defaultProps = defaultProps;

export default KiCanvas;
