import * as React from "react";
import theme from "@config/theme";

type ArgumentsContent = {
  children: React.ReactNode;
};

const Arguments = ({ children }: ArgumentsContent) => (
  <>
    <header>Arguments</header>
    <div className="arguments">
      {children || <div className="noneMessage">None</div>}
    </div>
    <style jsx>{`
      header {
        font-size: ${theme.fonts.size.px18};
        font-weight: 600;
        margin-top: 24px;
        margin-bottom: 16px;
      }

      .arguments {
        border: 1px solid ${theme.colors.snowBlue};
        border-radius: 4px;
        display: grid;
        grid-template-columns: 1fr 3fr; /* 25% 75% column widths */
        grid-template-areas: "arg info"; /* column names we can use below */
      }

      .noneMessage {
        padding: 16px;
      }

      // Font size
      .arguments :global(*) {
        font-size: ${theme.fonts.size.px14};
      }

      // Horizontal line divider between arguments
      .arguments :global(h3:not(:first-of-type)) {
        // above each h3
        border-top: 1px solid ${theme.colors.snowBlue};
      }

      .arguments :global(h3:not(:first-of-type) + *) {
        // and above the cell to the right of the h3
        border-top: 1px solid ${theme.colors.snowBlue};
      }

      .arguments :global(h3:first-child) {
        // but not the first h3
        border-top: 0px;
      }

      .arguments :global(h3:first-child + *) {
        // nor its neighbor
        border-top: 0px;
      }

      // Argument Names
      .arguments :global(h3 code) {
        font-size: ${theme.fonts.size.px14};
      }

      .arguments :global(h3) {
        margin: 0px;
        padding: 1rem;
        grid-column-start: arg;
      }

      // Arguments Data Type
      .arguments :global(h3 + p em) {
        font-size: ${theme.fonts.size.px16};
        font-style: normal;
        font-weight: 500;
        color: ${theme.colors.mediumGray};
        display: block;
      }

      .arguments :global(h3 + p) {
        padding-top: 1rem;
      }

      .arguments :global(p) {
        padding: 0px 1rem 1rem 1rem;
        grid-column-start: info;
        grid-column-end: info;
        margin-top: 0px;
        margin-bottom: 0px;
      }

      .arguments :global(p:has(code + span)) {
        display: flex;
        align-items: center;
        border-top: 1px solid ${theme.colors.snowBlue};
        padding-top: 1rem;
        flex-wrap: wrap;
        row-gap: 4px;
        column-gap: 4px;
      }

      .arguments :global(p:has(code + span) code) {
        flex-shrink: 0;
      }

      .arguments :global(.badge) {
        margin-left: 0;
      }

      /* Prevent URLs from causing the arguments container from taking up
      more than 50% of the screen width. */
      .arguments :global(p code) {
        overflow-wrap: anywhere;
      }

      @media screen and (max-width: ${theme.breakpoints.md}px) {
        .arguments :global(h3:not(:first-of-type) + *) {
          // remove border above the cell below the h3
          border-top: 0px;
        }

        .arguments :global(p) {
          grid-column-start: 1;
        }

        .arguments :global(h3 + *) {
          border-top: 0px;
        }

        .arguments :global(h3) {
          grid-column-end: -1;
        }

        .arguments :global(h3 + p) {
          padding-top: 0px;
        }
      }
    `}</style>
  </>
);

export default Arguments;
