const TwitterLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 18.689"
    width="24"
    height="24"
  >
    <g>
      <g id="Logo">
        <path
          fill="currentColor"
          d="m20.646,4.652c.014.202.014.406.014.61,0,6.236-4.747,13.426-13.426,13.426v-.004c-2.564.003-5.075-.731-7.233-2.116.373.045.748.067,1.123.068,2.125.002,4.189-.711,5.86-2.024-2.019-.038-3.79-1.355-4.409-3.277.707.136,1.436.109,2.131-.081-2.202-.445-3.785-2.379-3.786-4.626v-.06c.656.366,1.391.568,2.142.591C.988,5.775.348,3.017,1.601.861c2.396,2.948,5.931,4.74,9.725,4.93-.38-1.639.14-3.356,1.365-4.509,1.9-1.787,4.889-1.695,6.677.205,1.057-.208,2.07-.596,2.996-1.145-.352,1.093-1.09,2.02-2.075,2.61.935-.111,1.849-.361,2.71-.742-.634.947-1.431,1.775-2.354,2.443Z"
        />
      </g>
    </g>
  </svg>
);

export default TwitterLogo;
