import { ReactElement } from "react";

type Props = {
  children: ReactElement;
  cond: boolean;
};

const If = ({ children, cond }: Props) => (cond ? children : null);

export { If };
