/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState } from "react";
import theme from "@config/theme";
import { MenuItem } from "@components/layout/Menu";
import classNames from "classnames";
import { TrackedLink } from "./analytics/TrackedLink";

type DropDownMenuItemProps = {
  item: MenuItem;
  itemClick: (item: MenuItem, idx?: number) => void;
  setOpenIdx: (idx: number) => void;
  openIdx: number;
  idx: number;
  id: string;
  level: number;
};

const DropDownMenuItem = ({
  item,
  itemClick,
  setOpenIdx,
  openIdx,
  idx,
  id,
  level,
}: DropDownMenuItemProps) => (
  <>
    {item.href && !item.href.includes("http") ? (
      <TrackedLink
        className="dropdown-link tabLink"
        href={item.href}
        onMouseDown={(e) => {
          // prevent menu close
          e.preventDefault();
          e.stopPropagation();
          setOpenIdx(idx === openIdx ? -1 : idx);
        }}
      >
        <b className="dropdown-title">{item.title}</b>
        {item.subtitle && (
          <>
            <br />
            <small className="dropdown-subtitle">{item.subtitle}</small>
          </>
        )}
      </TrackedLink>
    ) : (
      <a
        href={item.href ? item.href : undefined}
        onMouseDown={(e) => {
          if (!item.items.length && !item.href) {
            itemClick(item, idx);
          } else {
            // prevent menu close
            e.preventDefault();
            e.stopPropagation();
            setOpenIdx(idx === openIdx ? -1 : idx);
          }
        }}
        className={classNames("dropdown-link", {
          "grayed-out": item.isGrayedOut,
        })}
      >
        <b className="dropdown-title">{item.title}</b>
        {item.items.length > 0 && (
          <div className={`arrow ${openIdx === idx ? "up" : "down"}`} />
        )}
      </a>
    )}
    {
      // display submenu items if open
      openIdx === idx &&
        item.items.map((child) => (
          <DropDownMenuItem
            key={child.title}
            item={child}
            itemClick={(child) => itemClick(child)}
            openIdx={openIdx}
            setOpenIdx={() => setOpenIdx(-1)}
            idx={idx}
            id={id}
            level={level + 1}
          />
        ))
    }

    <style jsx>{`
      /* Links inside the dropdown */
      .dropdown-link,
      :global(.tabContainer .dropdown-link.tabLink) {
        color: ${theme.colors.black};
        display: block;
        min-width: 174px;
        padding: 1rem;
        font-weight: 400;
        max-width: 300px;
        height: unset;
      }

      .dropdown-link:hover,
      :global(.dropdown-link.tabLink:hover) {
        background-color: ${theme.colors.gray5};
        color: ${theme.colors.black};
      }

      .dropdown-link.grayed-out {
        color: ${theme.colors.snowBlue};
      }

      .arrow {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
        transform: rotate(0deg);
      }

      .arrow.up {
        transform: rotate(180deg);
      }

      .dropdown-title {
        font-weight: 500;
      }
      .dropdown-subtitle {
        color: ${theme.colors.gray2};
        font-size: ${theme.fonts.size.px14};
      }
    `}</style>
  </>
);

type DropDownMenuProps = {
  items: MenuItem[];
  id: string;
  open: boolean;
  itemClick: (item: MenuItem, idx?: number) => void;
};

const DropDownMenu = ({ items, id, open, itemClick }: DropDownMenuProps) => {
  const [openIdx, setOpenIdx] = useState(-1);

  return (
    <div className={classNames("dropdown-content", { show: open })}>
      {items.map((item, idx) => (
        <DropDownMenuItem
          key={item.title}
          item={item}
          itemClick={(item) => itemClick(item, idx)}
          openIdx={openIdx}
          setOpenIdx={(i) => setOpenIdx(i)}
          idx={idx}
          id={id}
          level={1}
        />
      ))}

      <style jsx>{`
        /* Dropdown Content (Hidden by Default) */
        .dropdown-content {
          display: none;
          position: absolute;
          background-color: ${theme.colors.white};
          min-width: fit-content;
          box-shadow: ${theme.misc.boxShadowDropDownMenu};
          z-index: ${theme.zIndex.dropdown};
          cursor: pointer;
          transform: translate(-50%, 0);
          border-radius: 5px;
          border: 1px solid ${theme.colors.gray4};
        }

        /* Show the dropdown menu */
        .show {
          display: block;
        }
      `}</style>
    </div>
  );
};

type DropDownButtonProps = {
  btnClickableElement: JSX.Element;
  id: string;
  items: MenuItem[];
  onChange: (val: MenuItem, pos?: number) => void;
  remainOpen?: boolean;
};

const defaultProps = {
  remainOpen: false,
};

const DropDownButton = ({
  btnClickableElement,
  id,
  items,
  onChange,
  remainOpen,
}: DropDownButtonProps) => {
  const [open, setOpen] = useState(false);

  const itemClick = (val: MenuItem, idx?: number) => {
    if (!remainOpen) {
      setOpen(false);
    }
    onChange(val, idx);
  };

  const itemBlur = () => {
    if (!remainOpen) {
      setOpen(false);
    }
  };

  return (
    <>
      <div
        className={`dropdown dropdown-${id}`}
        key={id}
        onBlur={itemBlur}
        tabIndex={0}
      >
        <div className="dropbtn" onClick={() => setOpen(!open)}>
          {btnClickableElement}
        </div>
        <DropDownMenu
          items={items}
          id={id}
          open={open}
          itemClick={(val, pos) => itemClick(val, pos)}
        />
      </div>

      <style jsx>{`
        .dropbtn {
          height: 100%;
          border: none;
          background-color: transparent;
          padding: 0;
        }

        /* Dropdown button on hover & focus */
        .dropbtn:hover,
        .dropbtn:focus {
          text-decoration: none;
        }

        /* Dropdown arrow */
        .dropdown :global(.arrow) {
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid ${theme.colors.black};
        }

        .dropdown .dropbtn :global(.arrow) {
          margin-left: 4px;
          transform: ${open ? "rotate(180deg)" : "none"} !important;
        }

        .dropdown :global(.tabLink:hover .arrow) {
          border-top: 5px solid ${theme.colors.ultramarineTintLighter};
        }
      `}</style>
      <style jsx>{`
        .dropdown :global(.arrow-down) {
          transform: ${open ? "scaleY(-1)" : "none"};
        }
      `}</style>
    </>
  );
};

DropDownButton.defaultProps = defaultProps;

export default DropDownButton;
