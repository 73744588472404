import { useContext, ReactNode, useEffect } from "react";
import { SettingsContext } from "@components/settings/SettingsProvider";
import { LayoutContext } from "@components/layout/Layout";
import theme from "@config/theme";
import { metadata } from "@content/en/AnnouncementBanner.mdx";
import CloseButtonIcon from "@components/CloseButtonIcon";

interface AnnouncementBannerProps {
  children: ReactNode;
}

const AnnouncementBanner = ({ children }: AnnouncementBannerProps) => {
  const { settings, updateBannerSettings } = useContext(SettingsContext);
  const { isAnnouncementBannerShowing, setIsAnnouncementBannerShowing } =
    useContext(LayoutContext);

  useEffect(() => {
    if (!settings.closedBanners.includes(metadata.uniqueBannerId)) {
      setIsAnnouncementBannerShowing(true);
    } else {
      setIsAnnouncementBannerShowing(false);
    }
  }, [settings.closedBanners]);

  const closeAnnouncementBanner = () => {
    updateBannerSettings([...settings.closedBanners, metadata.uniqueBannerId]);
  };

  return (
    <div
      data-search-exclude
      className={`announcement-banner-container ${
        isAnnouncementBannerShowing === true ? "" : "hidden"
      }`}
    >
      {children}
      <button
        type="button"
        className="AnnouncementBanner Close"
        onClick={closeAnnouncementBanner}
        title="Dismiss Announcement Banner"
      >
        <CloseButtonIcon />
      </button>
      <style jsx>{`
        .announcement-banner-container {
          width: 100%;
          background: ${theme.colors.ultramarine};
          display: flex;
          padding: 2px 1.25rem;
          text-align: center;
          color: ${theme.colors.white};
        }

        .announcement-banner-container.hidden {
          display: none;
        }

        .AnnouncementBanner.Close {
          background: none;
          color: ${theme.colors.white};
          border: none;
          cursor: pointer;
        }

        .AnnouncementBanner.Close:hover {
          color: ${theme.colors.gray4};
        }

        @media only screen and (max-width: ${theme.breakpoints.md}px) {
          .announcement-banner-container {
            min-height: ${theme.announcementBanner.height.mediumScreen};
          }
        }

        @media only screen and (max-width: ${theme.breakpoints.sm}px) {
          .announcement-banner-container {
            min-height: ${theme.announcementBanner.height.smallScreen};
          }
        }
      `}</style>
    </div>
  );
};

export default AnnouncementBanner;
