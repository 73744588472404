import theme from "@config/theme";
import classNames from "classnames";
import { HeaderMenuData } from "@config/nav";
import { WhatsNew } from "@components/menu/WhatsNew";
import ResourcesMenu from "@components/menu/ResourcesMenu";
import { stringToURI } from "@scripts/utils";
import { MenuItem } from "@components/layout/Menu";
import { Tab } from "@components/menu/Tab";

type MobileTopNavMenuProps = {
  isOpen: boolean;
};

// mobile version of the top navbar dropdowns
// PLEASE NOTE: This component also gets resused in the Docs sidebar
const MobileTopNavMenu = ({ isOpen }: MobileTopNavMenuProps) => (
  <>
    <div className={classNames("mobile-top-nav-menu-wrapper", { isOpen })}>
      <div className="mobile-top-nav-item">
        <WhatsNew />
      </div>
      <div className="mobile-top-nav-item resources">
        <ResourcesMenu />
      </div>
      {HeaderMenuData.map((item: MenuItem) => (
        <div
          className={`mobile-top-nav-item ${item.title}`}
          key={stringToURI(item.title)}
        >
          <Tab {...item} />
        </div>
      ))}
    </div>
    <style jsx>
      {`
        .mobile-top-nav-menu-wrapper {
          z-index: ${theme.zIndex.mobileMenu};
          position: fixed;
          height: 100%;
          width: 100%;
          background-color: ${theme.colors.gray5};
        }

        .mobile-top-nav-item:hover {
          background-color: ${theme.colors.devGray};
        }

        .mobile-top-nav-item :global(.badge) {
          left: 117px;
          top: 8px;
        }

        /* this sets a hard height for each nav item besides the Resources dropdown, 
        which needs room to grow and push the other items beneath it when it's open
        instead of overlaying them like it does when part of the top nav bar*/
        .mobile-top-nav-item:not(.resources) {
          height: 42px;
        }

        .mobile-top-nav-item :global(.tabContainer span:not(.tabTitle)) {
          margin-left: 20px;
          font-size: ${theme.fonts.size.px16};
          color: ${theme.colors.black};
        }

        .mobile-top-nav-menu-wrapper :global(.tabContainer .tabLink) {
          justify-content: flex-start;
          height: 42px;
        }

        .mobile-top-nav-menu-wrapper :global(.tabContainer .tabLink .arrow),
        .mobile-top-nav-menu-wrapper :global(.dropdown .tabLink:hover .arrow) {
          border-top: 5px solid ${theme.colors.black};
        }

        .mobile-top-nav-menu-wrapper :global(.tabContainer span) {
          font-weight: 500;
          color: ${theme.colors.black};
        }

        .mobile-top-nav-menu-wrapper
          :global(.tabContainer .tabLink:hover span) {
          color: ${theme.colors.black};
        }

        .mobile-top-nav-menu-wrapper
          :global(.tabContainer .tabLink span .tabTitle) {
          display: inline-block;
          font-size: ${theme.fonts.size.px16};
        }

        .mobile-top-nav-menu-wrapper :global(.tabContainer .dropdown-content) {
          position: relative;
          box-shadow: none;
          margin: 0;
          left: 50%;
          border: none;
        }

        .mobile-top-nav-menu-wrapper :global(.tabContainer .dropdown-link) {
          padding: 0.5rem 3rem;
          background-color: ${theme.colors.gray5};
          height: unset;
        }

        .mobile-top-nav-menu-wrapper
          :global(.tabContainer .dropdown-link:hover) {
          background-color: ${theme.colors.devGray};
        }

        @media screen and (max-width: ${theme.breakpoints.sm}px) {
          .mobile-top-nav-item :global(.badge) {
            left: 108px;
          }
        }
      `}
    </style>
  </>
);
export default MobileTopNavMenu;
