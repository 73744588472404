import React, { useEffect, useState } from "react";
import theme from "@config/theme";
import useScript from "react-script-hook/lib/use-script";
import { Config } from "@configured/EnvironmentConfig";
import { Tab } from "./Tab";

const debug = false;

const debugLog = debug ? console.log : () => {}; // eslint-disable-line no-console

interface Widget {
  open: () => void;
  close: () => void;
}

let isAnnounceKitSetUp = false;

declare global {
  interface Window {
    announcekit?: {
      on: (
        event: string,
        callback: (c: { widget?: Widget; unread?: number }) => void
      ) => void;
      push: (prop: { widget: string; selector: string; name: string }) => void;
      widget$mack?: Widget;
    };
  }
}

const WhatsNew = () => {
  const [numUnread, setNumUnread] = useState(0);

  const [announceKitLoading, announceKitError] = useScript({
    src: "https://cdn.announcekit.app/widget-v2.js",
  });

  useEffect(() => {
    if (announceKitLoading) return;
    if (announceKitError) return;
    if (!window.announcekit) return;

    if (!isAnnounceKitSetUp) {
      debugLog("pushing announcekit widget");
      window.announcekit.push({
        widget: Config.announceKitWidgetURL,
        selector: `doesNotMatter-${Math.round(100000 * Math.random())}`,
        name: "mack",
      });
      isAnnounceKitSetUp = true;
    }

    debugLog("setting announcekit listener");
    window.announcekit.on("widget-unread", ({ unread }) => {
      // Called when unread post count of specified widget has been updated
      debugLog("announcekit unread", unread);
      setNumUnread(unread || 0);
    });
  }, [announceKitLoading, announceKitError]);

  const handleClick = (
    event?: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    window.announcekit?.widget$mack?.open();
    event?.preventDefault();
  };

  return (
    <>
      <div className="badge-container">
        <Tab
          selected={false}
          items={[]}
          level={1}
          title="What’s New"
          type="link"
          href="https://new.blues.dev/"
          onClick={handleClick}
        />

        {numUnread > 0 && <span className="badge" />}
      </div>

      <style jsx>{`
        .badge-container {
          position: relative;
        }
        .badge {
          width: 10px;
          height: 10px;
          background: ${theme.colors.poppyRed};
          border-radius: 10px;
          position: absolute;
          right: 7px;
          top: 10px;
        }

        @media screen and (max-width: ${theme.breakpoints.md}px) {
          .badge {
            right: 0;
          }
        }
      `}</style>
    </>
  );
};

const forceNamedExport = {};
export default forceNamedExport;
export { WhatsNew };
