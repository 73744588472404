import { FunctionComponent, ReactNode } from "react";
import theme from "@config/theme";
import { TrackedLink } from "@components/analytics/TrackedLink";
import BannerContent from "@content/en/AnnouncementBanner.mdx";

interface CustomExternalLinkProps {
  href: string;
  children: ReactNode;
}

// this variable exists solely so links in this MDX banner will open in new tabs like they do with the banner on the blues.com site
const CustomExternalLink: FunctionComponent<CustomExternalLinkProps> = ({
  href,
  children,
}) => (
  <TrackedLink data-analytics-location="announcement-banner" href={href}>
    {children}
  </TrackedLink>
);

const BannerContentComponent = () => (
  <>
    <div className="banner-text-wrapper">
      <BannerContent components={{ a: CustomExternalLink }} />
    </div>
    <style jsx>
      {`
        .banner-text-wrapper {
          margin: auto;
          font-size: 16px;
          font-weight: 600;
        }

        :global(.banner-text-wrapper p) {
          margin: 4px 0;
          font-size: ${theme.fonts.size.px14};
        }

        :global(.banner-text-wrapper p > a) {
          color: ${theme.colors.saffronYellow};
        }

        :global(.banner-text-wrapper p > a:hover) {
          color: ${theme.colors.saffronYellowTint};
          text-decoration: underline;
        }
      `}
    </style>
  </>
);
export default BannerContentComponent;
