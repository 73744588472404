import Link from "next/link";
import theme from "@config/theme";

type BreadcrumbsProps = {
  links: { name: string; url: string }[];
};

const Breadcrumbs = ({ links }: BreadcrumbsProps) => (
  <>
    <div className="breadcrumbs">
      <Link className="breadcrumbs-link" href="/">
        <span className="material-icons-round">home</span>
      </Link>

      {links.map((link, index) => (
        <span key={link.name} className="breadcrumbs-entry">
          <span className="divider material-icons-round">chevron_right</span>

          {index === links.length - 1 ? (
            <span className="breadcrumbs-link">{link.name}</span>
          ) : (
            <Link className="breadcrumbs-link" href={link.url}>
              {link.name}
            </Link>
          )}
        </span>
      ))}
    </div>
    <style jsx>
      {`
        .breadcrumbs {
          display: flex;
          margin: 1rem 0 0.5rem 0;
        }
        .breadcrumbs-entry {
          display: flex;
        }
        .breadcrumbs .divider {
          display: inline-block;
          margin: 0 0.5rem;
        }
        @media (max-width: ${theme.breakpoints.md}px) {
          .breadcrumbs-entry:last-child {
            overflow: hidden;
          }
          span.breadcrumbs-link {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      `}
    </style>
  </>
);

export default Breadcrumbs;
