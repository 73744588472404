import {
  LinkSummary,
  SmartLink,
  SmartLinkProps,
} from "@components/doc/SmartLink";

import { AnalyticsGateway } from "./AnalyticsGateway";

export const TrackedLink = (props: SmartLinkProps) => {
  const trackLinkClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    { href, type }: LinkSummary
  ) => {
    if (href.startsWith("mailto:")) {
      AnalyticsGateway().trackEvent("Link to Email Clicked", {
        href,
        analyticsLocation: event.currentTarget.getAttribute(
          "data-analytics-location"
        ),
      });
      return;
    }
    if (type !== "external") return;
    AnalyticsGateway().trackEvent("Link to External Site Clicked", {
      href,
      analyticsLocation: event.currentTarget.getAttribute(
        "data-analytics-location"
      ),
    });
  };

  return (
    <SmartLink {...props} onClickPreamble={trackLinkClick}>
      {props.children}
    </SmartLink>
  );
};
