import theme from "@config/theme";
import CloseButtonIcon from "@components/CloseButtonIcon";

export type WarningBannerProps = {
  title: string;
  desc: string;
  onCloseClick?: () => void;
  isHidden: boolean;
};

const WarningBanner = ({
  title,
  desc,
  onCloseClick,
  isHidden,
}: WarningBannerProps) => (
  <div className={`container ${isHidden ? "hidden" : ""}`}>
    <span className="banner-text-wrapper">
      <p className="title">{title}</p>
      <p>{desc}</p>
    </span>
    <button
      type="button"
      className="WarningBanner Close"
      onClick={onCloseClick}
      title="Hide Warning Banner"
    >
      <CloseButtonIcon />
    </button>

    <style jsx>{`
      .container {
        display: flex;
        flex-direction: row;
        width: 100%;
        background: ${theme.colors.saffronYellowShade};
        padding: 1rem 1.25rem;
        color: ${theme.colors.white};
        min-height: 115px;
      }

      .container.hidden {
        display: none;
      }

      p {
        margin: 0 auto;
        line-height: 1.2;
        font-size: 14px;
      }

      .title {
        font-weight: 600;
        margin-bottom: 0.5rem;
        font-size: 16px;
      }

      .WarningBanner {
        background: none;
        border: none;
        cursor: pointer;
        color: ${theme.colors.white};
        align-self: flex-start;
      }
    `}</style>
  </div>
);

export default WarningBanner;
